import styled from 'styled-components';
import { theme } from 'styled-tools';

export const HistoryWrapper = styled.div`
    width: 80%;
    margin: auto;
    margin-top: 1rem;
    align-items: center;

    .history-header {
        margin: ${theme('dims.bigSpacing')};
    }
`;