import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';
import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { createReducer } from './reducer';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import { rootEpic } from './epics';

const reducer = createReducer();
const epicMiddleware = createEpicMiddleware();
const middleware = applyMiddleware(epicMiddleware);

let composeEnhancers, store;

composeEnhancers = compose;
store = createStore(reducer, undefined, composeEnhancers(middleware));

epicMiddleware.run(rootEpic);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Modal.setAppElement('#root');

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
