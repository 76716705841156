import { ofType } from 'redux-observable';
import { map, switchMap, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { SET_TOKEN } from '../Login/const';
import { tokenSelector } from '../Login/selectors';
import { getHelpers, placeOffer } from './api';
import { setHelpers, setOffersForHelper } from './actions';


export const updateHelpersEpic = (action$, state$) =>
    action$.pipe(
        ofType(SET_TOKEN),
        map(() => ({
            token: tokenSelector(state$.value)

        })),
        switchMap(({token}) =>
        from(getHelpers(token)).pipe(map(( helpers ) => setHelpers(helpers))))
    )

/*
export const fetchHelpersForUserEpic = (action$, state$) =>
  action$.pipe(
      ofType(SET_HELPER_NEEDS_UPDATE),
      map((action) => ({
        key: action.key,
        ppe: selectedPpeSelector(state$.value),
        token: tokenSelector(state$.value)
      })),
      switchMap(({key,ppe,token}) =>
      from(getOffers(key, ppe, token)).pipe(
          map(offers => setOffersForHelper(key, offers))))
  );


export const postOfferEpic = (action$, state$) =>
  action$.pipe(
    ofType(SET_PLACED_OFFER),
    map((action) => ({
      token: tokenSelector(state$.value),
      offer: action.offer,
      ppe: selectedPpeSelector(state$.value)
    })),
    switchMap(({token, offer, ppe}) =>
      from(placeOffer(offer, token, ppe)).pipe(
        mergeMap((isOK) => 
          of(
            setHelperNeedsUpdate(offer.helper_id),
            setProfileNeedsUpdate()
          )
        )
      )
    )
  );

export const deleteOfferEpic = (action$, state$) =>
action$.pipe(
    ofType(DELETE_OFFER),
    map((action) => ({
        token: tokenSelector(state$.value),
        offer_id: action.offer_id,
        helper_id: action.helper_id
    })),
    switchMap(({token, offer_id, helper_id}) =>
    from(deleteOffer(offer_id, token)).pipe(map(( isOK ) =>  setHelperNeedsUpdate(helper_id))))
);
*/