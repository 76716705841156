import { Amount } from "../../../Wallet/DetailedTransactionsList/DetailedTransaction/Amount";
import { TransactionType } from "../../../Wallet/DetailedTransactionsList/DetailedTransaction/TransactionType";
import { TransactionWrapper } from "./TransactionWrapper";
import { parseDate, parseMoney } from '../../../../utils'

export interface TransactionProps {
    key: string,
    id: string,
    date: string,
    amount: number,
    type: TransactionType
}

function Transaction(props: TransactionProps) {
    return (
        <TransactionWrapper type={props.type}>
            <div className="transaction-field">
                {parseDate(props.date)}
            </div>
            <Amount type={props.type}>
            {props.type === TransactionType.Withdrawal || props.type === TransactionType.Credit ? "-" : ""}{parseMoney(props.amount)}zł
            </Amount>
        </TransactionWrapper>
    )
}

export default Transaction;