import { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuctionMenu from './AuctionMenu';
import History from './History';
import Profile from './Profile';
import Navbar from './Navbar';
import Login from './Login';
import MyPPE from './MyPPE';
import Logout from './Logout';
import Helper from './Helpers';
import Welcome from './Welcome';
import Registration from './Registration';
import NotFound from './NotFound';
import Wallet from './Wallet';
import MyDoc from './MyDocs';
import RegistrationSuccess from "./RegistrationSuccess";
import VerifyEmail from "./VerifyEmail";

import { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from './themes';
import { GlobalStyle } from './global-styles';
import { useState } from 'react';
import { GlobalErrorModal } from './Error';
import PrivateRoute from './PrivateRoute';
import CookieConsent from "react-cookie-consent";

export const App = () => {
    const [theme, setTheme] = useState('light');
    const themeToggler = () => {
        theme === 'light' ? setTheme('dark') : setTheme('light');
    };

    return (
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
            <>
                <GlobalStyle />
                <Router>
                    <Fragment>
                        <Navbar themeToggler={themeToggler} />
                        <GlobalErrorModal />
                        <Routes>
                            <Route path="/" element={<Login />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/registration" element={<Registration />} />
                            <Route path="/registration-success" element={<RegistrationSuccess />} />
                            <Route path="/activate/:uidb64/:token" element={<VerifyEmail />} />
                            <Route path="/" element={<PrivateRoute />}>
                                <Route path="index.html" element={<Welcome />} />
                                <Route path="auctions" element={<AuctionMenu />} />
                                <Route path="history" element={<History />} />
                                <Route path="profile" element={<Profile />} />
                                <Route path="wallet" element={<Wallet />} />
                                <Route path="mojeppe" element={<MyPPE />} />
                                <Route path="mojedokumenty" element={<MyDoc />} />
                                <Route path="moipomocnicy" element={<Helper />} />
                                <Route path="logout" element={<Logout />} />
                            </Route>
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                        <CookieConsent
                            buttonText="Zrozumiano"
                            >
                                Ta strona używa plików cookie w celu usprawnienia i ułatwienia dostępu do serwisu.
                        </CookieConsent>
                    </Fragment>
                </Router>
            </>
        </ThemeProvider>
    );
};

export default App;
