import { useSelector } from 'react-redux';
import { getOffersByKey } from '../../selectors';
import Offer from './Offer';
import { OfferListWrapper } from './OfferListWrapper';

const AuctionOffers = ({ auctionId }) => {
  const offers = useSelector((state) => getOffersByKey(state, auctionId));

  return (
    <div>
      <h2>Moje oferty</h2>
      <hr></hr>
      <OfferListWrapper>
        {offers.map((offer) => (
          <Offer key={offer.get("id")}
            id={offer.get("id")}
            auction={auctionId}
            amount={offer.get("energy_amount")}
            price={offer.get("price")}
            direction={offer.get("direction")} />
        )
        )}
      </OfferListWrapper>
    </div>
  );
};

export default AuctionOffers;