import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import {from, of} from 'rxjs';
import { SET_TOKEN } from '../Login/const';
import { setProfileData, setPPE } from '../Profile/actions'
import { tokenSelector } from '../Login/selectors';
import { getProfileFromServer, editProfile, getPPE } from './api';
import { SET_EDITED_PROFILE, SET_PROFILE_NEEDS_UPDATE } from './const';
import { editedProfileSelector } from './selector';
import {navigateTo} from "../Registration/actions";
import {setGlobalError} from "../Error/actions";

export const fetchProfileEpic = (action$, state$) =>
  action$.pipe(
    ofType(SET_TOKEN, SET_PROFILE_NEEDS_UPDATE),
    map(() => ({
      token: tokenSelector(state$.value)
    })),
    switchMap(({ token }) =>
      from(getProfileFromServer(token)).pipe(
        map(profile => setProfileData(profile))
      )
    )
  );

export const editProfileEpic = (action$, state$) =>
  action$.pipe(
    ofType(SET_EDITED_PROFILE),
    map(() => ({
        token: tokenSelector(state$.value),
        profile: editedProfileSelector(state$.value)
    })),
    switchMap(({token, profile}) =>
      from(editProfile(profile, token)).pipe(
        switchMap(({ success, errorMessage, profile }) =>
            success ?
            of(map(profile => setProfileData(profile))) :
            of(setGlobalError(errorMessage))
        )
      )
    )
  );
