import { SET_HELPERS, SET_HELPER_NEEDS_UPDATE} from "./const"

export const setHelpers = (helpers) => ({
    type: SET_HELPERS,
    helpers
})

export const setHelperNeedsUpdate = (key) => ({
    type: SET_HELPER_NEEDS_UPDATE,
    key
});