import styled from 'styled-components';
import { theme } from 'styled-tools';


export const MenuItemWrapper = styled.div`
    display:inline;
    padding: ${theme('dims.bigSpacing')};
    & a {
        text-decoration: none;
        color: ${theme('colors.text')};
    }
    &:hover a{
        color: ${theme('colors.text_highlight')};
    }
`;

export const ProfileButton = styled.button`
display:inline;
padding: ${theme('dims.bigSpacing')};
& a {
    text-decoration: none;
    color: ${theme('colors.text')};
}
&:hover a{
    color: ${theme('colors.text_highlight')};
}
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  user-select: none;
`;

export const LogoText = styled.div`
  font-size: 48px;
  background: -webkit-linear-gradient(left, lightgreen, deepskyblue);
  background: linear-gradient(to right, lightgreen, deepskyblue);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  align-self: flex-start;
  text-shadow: 2px 7px 5px rgba(0,0,0,0.7), 0px -4px 10px rgba(255,255,255,0.2);
`;

export const CompanyText = styled.div`
  font-size: 17px;
  position: absolute;
  top: 45px;
  left: 38px;
  background: -webkit-linear-gradient(left, lightgreen, deepskyblue);
  background: linear-gradient(to right, lightgreen, deepskyblue);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 2px 7px 5px rgba(0,0,0,0.7), 0px -4px 10px rgba(255,255,255,0.2);
`;