import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { theme } from 'styled-tools';
import 'react-datepicker/dist/react-datepicker.css';

export const AuctionMenuWrapper = styled.div`
    padding-left: ${theme('dims.hugeSpacing')};
    display: grid;
    grid-template-areas:
      'userinfo chart'
      'helper chart'
      'auctions auctions';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 200px 100px 1fr;

    .userinfo { grid-area: userinfo; margin: ${theme('dims.bigSpacing')}; }
    .chart { grid-area: chart; margin: ${theme('dims.bigSpacing')};}
    .helper { grid-area: helper; margin: ${theme('dims.bigSpacing')}; margin-top: 0px}
    .auctions-list { grid-area: auctions; width: 95%; margin: ${theme('dims.bigSpacing')};}
    .auctions-list-header { text-shadow: 2px 7px 5px rgba(0,0,0,0.7), 0px -4px 10px rgba(255,255,255,0.4);}
`;

export const DatePickerWrapper = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    width: 100%;
`;

export const StyledDatePicker = styled(DatePicker)`
    border-radius: 12px;
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    width: 50%;
    box-sizing: border-box;
    margin-left: 10px;
`;

export const DataChoiceLabel = styled.label`
    margin-left: 40px;
    white-space: nowrap;
`;
