import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { from, of} from 'rxjs';
import { SET_LOGIN_DETAILS } from './const';
import { loginDetailsSelector } from './selectors';
import { getTokenFromLogin } from './api';
import { setToken } from './actions';
import { setGlobalError } from '../Error/actions';

export const getTokenFromLoginEpic = (action$, state$) =>
    action$.pipe(
        ofType(SET_LOGIN_DETAILS),
        map(() => ({
            loginDetails: loginDetailsSelector(state$.value)
        })),
        switchMap(({ loginDetails }) =>
            from(getTokenFromLogin(loginDetails)).pipe(
                switchMap(({ success, token, errorMessage }) => 
                    success ? 
                    of(setToken(token)) :
                    of(setGlobalError(errorMessage))
            )))
    )