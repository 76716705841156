import { MenuItemWrapper } from './MenuItemWrapper';
import { Link } from 'react-router-dom';

interface MenuItemProps {
    text: string;
    href: string;
}

function MenuItem(props: MenuItemProps) {
    return (
        <MenuItemWrapper>
            <div style={{ display: 'inline-block', textAlign: 'left', verticalAlign: 'bottom'}} className="menu-item">
                <Link to={props.href}>
                    {props.text}
                </Link>
            </div>
        </MenuItemWrapper>
    );
}

export default MenuItem;