import { SET_ADDED_PPE, SET_PPE_NEED_UPDATE } from "./const";
import { SET_TOKEN } from "../Login/const";
import { tokenSelector } from "../Login/selectors";
import { ofType } from "redux-observable";
import { switchMap, from, of, mergeMap, map } from "rxjs";
import { setGlobalError } from "../Error/actions";
import { getPPE, addPPE } from "./api";
import { setPPENeedUpdate, setPPE } from "./actions";

export const fetchPPEEpic = (action$, state$) =>
    action$.pipe(
      ofType(SET_TOKEN, SET_PPE_NEED_UPDATE),
      map(() => ({
          token: tokenSelector(state$.value),
      })),
      switchMap(({token}) =>
        from(getPPE(token)).pipe(
          map(ppe => setPPE(ppe))
        )
      )
    );


export const postPPEEpic = (action$, state$) =>
    action$.pipe(
        ofType(SET_ADDED_PPE),
        map((action) => ({
        token: tokenSelector(state$.value),
        ppe: action.ppe
        })),
        switchMap(({token, ppe}) =>
        from(addPPE(token, ppe)).pipe(
            mergeMap(({success, errorMessage}) => success ? 
            of(
                setPPENeedUpdate()
            ) :
            of(setGlobalError(errorMessage))
            )
        )
        )
    );
      
  