import DetailedPPEsList from "./DetailedPPEList";

import { PPEWrapper, PPEInfoWrapper } from "./styles";
import { useSelector } from "react-redux";
import AddPPE from "./AddPPE";
import { ppeSelector } from "./selectors";

function MyPPE() {
    const ppes = useSelector(ppeSelector);
    const ppeCount = ppes.size;

    return (
        <PPEWrapper>
            <AddPPE />
            <PPEInfoWrapper style={{ width: '60%', alignItems: 'center' }}>
                <div>Punkty poboru energii</div>
                <hr />
                <div>Liczba podłączonych PPE: {ppeCount}</div>
                <DetailedPPEsList PPEs={ppes.toJS()} />
            </PPEInfoWrapper>
        </PPEWrapper>
    );
}

export default MyPPE;