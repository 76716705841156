import { createSelector } from 'reselect';
import { prop } from 'ramda';
import { PPE_REDUCER_NAME } from './reducer';

const getPPEReducerState = prop(PPE_REDUCER_NAME);

export const ppeSelector = createSelector(
    getPPEReducerState,
    (ppeState) => (ppeState.get('ppe'))
);

export const selectedPpeSelector = createSelector(
    getPPEReducerState,
    (ppeState) => (ppeState.get('selected_ppe'))
);

export const selectedPpeDataSelector = createSelector(
    getPPEReducerState,
    (ppeState) => {
        const ppeList = ppeState.get('ppe');
        const selectedPpeId = ppeState.get('selected_ppe');
        if (ppeList && ppeList.size > 0 && selectedPpeId) {
            return ppeList.find(ppe => ppe.get('id') === selectedPpeId).toJS();
        } else {
            return {};
        }
    }
);