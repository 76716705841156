import { createSelector } from 'reselect';
import { prop } from 'ramda';
import { profileSelector } from "../Profile/selector";
import { useSelector } from 'react-redux';

import { HELPERS_REDUCER_NAME } from './reducer';

const getHelpersReducerState = prop(HELPERS_REDUCER_NAME);

export const helpersSelector = createSelector(
    getHelpersReducerState,
    (act_state) => {
        console.log("act state within selector:", act_state)
        console.log("get helpers within selector:", act_state.get('helpers'))
        return act_state.get('helpers'); // Corrected to 'helpers'
    }
);
export const getHelpersByUser = createSelector(
    getHelpersReducerState,
    (act_state) => {
        const helpers = act_state.get('helpers');
        console.log("does user work:", useSelector(profileSelector).profile.username)
        console.log(helpers.filter(helper => helper.get('user') === useSelector(profileSelector).profile.username));
        return helpers.filter(helper => helper.get('user') === useSelector(profileSelector).profile.username);
    }
);
