import { Amount } from "./Amount";
import { TransactionType } from "./TransactionType";
import { TransactionFieldWrapper, TransactionWrapper } from "./TransactionWrapper";
import { parseDate, parseMoney, parseTransactionType } from '../../../../utils'

export interface TransactionProps {
    id: string,
    date: string,
    amount: number,
    type: TransactionType
}

function DetailedTransaction(props: TransactionProps) {
    return (
        <TransactionWrapper type={props.type}>
            <TransactionFieldWrapper style={{'marginBottom': '0.5em'}}>
                Identyfikator operacji: {props.id}
            </TransactionFieldWrapper>
            <TransactionFieldWrapper style={{'textAlign': 'right', 'marginBottom': '0.5em'}}>
                Data: {parseDate(props.date)}
            </TransactionFieldWrapper>
            <TransactionFieldWrapper>
                Rodzaj: {parseTransactionType(props.type)}
            </TransactionFieldWrapper>
            <TransactionFieldWrapper>
                <Amount type={props.type}>
                {props.type === TransactionType.Withdrawal || props.type === TransactionType.Credit ? "-" : ""}{parseMoney(props.amount)}zł
                </Amount>
            </TransactionFieldWrapper>
        </TransactionWrapper>
    )
}

export default DetailedTransaction;