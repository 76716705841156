import { fromJS } from 'immutable';
import { SET_LOGIN_DETAILS, SET_TOKEN, CLEAR_TOKEN } from './const';

export const LOGIN_REDUCER_NAME = 'Login';

const initialState = fromJS({
    username: "Jan_Kowalski",
    password: "p455w04d",
    token: ""//localStorage.getItem('token')
})

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOGIN_DETAILS: {
            const { username, password } = action;
            console.log('trying to log-in with name:' + username + "and password" + password)
            return state.set("username", username).set("password", password)
        }
        case SET_TOKEN: {
            const { token } = action;
            //localStorage.setItem('token', token);
            if (token === undefined) { //TODO temporary fix
                console.log('trying to set bad token')
                return state
            }
            console.log('setting token to:' + token)
            return state.set("token", token)
        }
        case CLEAR_TOKEN: {
            //localStorage.setItem('token', '');
            console.log('clearing token')
            return state.set("token", '')
        }
        default:
            return state;
    }
}