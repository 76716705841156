import styled from 'styled-components';
import { theme } from 'styled-tools';
import { TransactionType } from './TransactionType';

type TransactionProps = {
  type?: TransactionType;
};

// Use the defined type for your styled component
export const TransactionWrapper = styled.div<TransactionProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${theme('dims.smallSpacing')};  margin-left: ${theme('dims.smallSpacing')};
  margin-right: ${theme('dims.smallSpacing')};
  border-radius: 3px;
  color: ${theme('colors.panel_text')};
  background: linear-gradient(180deg, ${theme('colors.item_background')}, ${theme('colors.second_item_background')});
`;

export const TransactionFieldWrapper = styled.div<TransactionProps>`
  flex: 50%;
  padding: 0.25em 0
`