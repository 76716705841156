import { useCallback, useState, useEffect } from "react";
import { AccountDetailsWrapper } from "./AccountDetailsWrapper";
import { Input } from "./Input";
import { useDispatch } from "react-redux";
import { setEditedProfile } from "../actions";
import { parseBankAccountNumber } from "../../../utils";

export interface AccountDetailsProps {
    username: string,
    email: string,
    first_name: string,
    last_name: string,
    address: string,
    companyName: string,
    nip: string,
    registrationDate: string,
    bank_account_number: string
}

function AccountDetails(props: AccountDetailsProps) {
    const [username, setUsername] = useState(props.username);
    const [email, setEmail] = useState(props.email);
    const [first_name, setFirstName] = useState(props.first_name);
    const [last_name, setLastName] = useState(props.last_name);
    const [bank_account_number, setBankAccountNumber] = useState(props.bank_account_number);

    const isModified = () => {
        return username !== props.username || email !== props.email || first_name !== props.first_name || last_name !== props.last_name || bank_account_number !== props.bank_account_number;
    };

    useEffect(() => {
        setUsername(props.username);
        setEmail(props.email);
        setFirstName(props.first_name);
        setLastName(props.last_name);
        setBankAccountNumber(props.bank_account_number);
    }, [props.username, props.email, props.first_name, props.last_name, props.bank_account_number]);

    const dispatch = useDispatch();
    const onEditClick = useCallback((event: { preventDefault: () => void; }) => {
        event.preventDefault();
        dispatch(setEditedProfile({"username": username, "email": email, "first_name": first_name, "last_name": last_name, "bank_account_number": bank_account_number}));
    }, [dispatch, username, email, first_name, last_name, bank_account_number]);

    return (
        <AccountDetailsWrapper>
            <label>Nazwa użytkownika</label>
            <Input 
                type="text" 
                id="username" 
                name="username" 
                value={username}
                pattern="^[\w.@+\-]+$"
                maxLength={150}
                minLength={1}
                required
                onChange={event => setUsername(event.target.value)} />
            
            <label>Adres email</label>
            <Input 
                type="email" 
                id="email" 
                name="email" 
                value={email}
                maxLength={254}
                required
                onChange={event => setEmail(event.target.value)} />

            <label>Imię</label>
            <Input 
                type="text" 
                id="first_name" 
                name="first_name" 
                value={first_name}
                maxLength={150}
                onChange={event => setFirstName(event.target.value)} />

            <label>Nazwisko</label>
            <Input 
                type="text" 
                id="last_name" 
                name="last_name" 
                value={last_name}
                maxLength={150}
                onChange={event => setLastName(event.target.value)} />

            <label>Numer konta</label>
            <Input 
                type="text" 
                id="bank_account_number" 
                name="bank_account_number" 
                value={bank_account_number}
                maxLength={26}
                onChange={event => setBankAccountNumber(event.target.value)} />

            <label>Adres</label>
            <Input 
                type="text" 
                id="address" 
                name="address" 
                value={props.address}
                disabled />

            <label>Data rejestracji</label>
            <Input 
                type="text" 
                id="registration_date" 
                name="registration_date" 
                value={props.registrationDate}
                disabled />

            <label>Firma</label>
            <Input 
                type="text" 
                id="company_name" 
                name="company_name" 
                value={props.companyName}
                disabled />

            <label>NIP</label>
            <Input 
                type="text" 
                id="nip" 
                name="nip" 
                value={props.nip}
                disabled />

            {isModified() && <button onClick={onEditClick}>Zapisz zmiany</button>}
        </AccountDetailsWrapper>
    );
}

export default AccountDetails;