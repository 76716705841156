import { SET_AUCTIONS, SET_OFFERS_FOR_AUCTION, SET_AUCTION_NEEDS_UPDATE, SET_PLACED_OFFER, DELETE_OFFER, SET_HELPERS } from "./const"

export const setAuctions = (auctions) => ({
    type: SET_AUCTIONS,
    auctions
})

export const setOffersForAuction = (auction, offers) => ({
    type: SET_OFFERS_FOR_AUCTION,
    auction,
    offers
})

export const setAuctionNeedsUpdate = (key) => ({
    type: SET_AUCTION_NEEDS_UPDATE,
    key
});

export const setPlacedOffer = (offer) => ({
    type: SET_PLACED_OFFER,
    offer
})

export const deleteOffer = (offer_id, auction_id) => ({
    type: DELETE_OFFER,
    offer_id,
    auction_id
})

export const setHelpers = (helpers) => ({
    type: SET_HELPERS,
    helpers
})