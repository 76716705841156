import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from '../Login/selectors';
import { NavbarWrapper } from './NavbarWrapper';
import MenuItem from './MenuItem';
import { CompanyText, LogoContainer, LogoText } from './MenuItem/MenuItemWrapper';
import { profileSelector } from '../Profile/selector';
import { ToggleSwitch } from './ToggleSwitch';
import { parseMoney } from '../../utils';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { useNavigate } from 'react-router-dom';
import { setSelectedPPE } from '../MyPPE/actions';
import { ppeSelector } from '../MyPPE/selectors';
import { DropdownWrapper } from './NavbarWrapper';

function Navbar({ themeToggler }) {
    const token = useSelector(tokenSelector);
    const portfolioValue = useSelector(profileSelector).profile.balance;
    const ppe = useSelector(ppeSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const options = [
        { value: 'profile', label: 'Moje konto' },
        { value: 'wallet', label: 'Mój portfel' },
        { value: 'mojeppe', label: 'Moje PPE' },
        { value: 'mojedokumenty', label: 'Moje dokumenty' },
        { value: 'moipomocnicy', label: 'Moi pomocnicy' },
        { value: 'logout', label: 'Wyloguj' },
    ];
    const defaultOption = options[0];

    const handleMenuSelect = (option) => {
        navigate(`/${option.value}`);
    };

    const handlePPESelect = (option) => {
        dispatch(setSelectedPPE(option.value));
    }

    return (
        <NavbarWrapper >
            <a href="/" style={{ textDecoration: 'none' }}>
                <LogoContainer>
                    <LogoText>perun</LogoText>
                    <CompanyText>by Hexanity</CompanyText>
                </LogoContainer>
            </a>

            <ToggleSwitch toggleAction={themeToggler} />
            {token && token.token.toString().localeCompare('') ?
                <>
                    <MenuItem id="aukcje" text="Aukcje" href="/auctions" />
                    <MenuItem id="historia" text="Historia" href="/history" />
                </>
                :
                <>
                    <MenuItem id="aukcje" text="Aukcje" href="/login" />
                    <MenuItem id="historia" text="Historia" href="/login" />
                </>}
            {token && token.token.toString().localeCompare('') ?
                <>
                    <MenuItem id="balance" text={`Stan Portfela: ${parseMoney(portfolioValue)} zł`} href="/wallet" />
                    <DropdownWrapper>
                        <Dropdown options={ppe.map((ppek) => ({value: ppek.get("id"), label: ppek.get("id")})).toArray()} onChange={handlePPESelect} placeholder="PPE" />
                    </DropdownWrapper>
                    <DropdownWrapper>
                        <Dropdown options={options} onChange={handleMenuSelect} value={defaultOption.label} placeholder="Menu profilowe" />
                    </DropdownWrapper>

                </>
                :
                <>
                    <MenuItem id="login" text="Zaloguj się" href="/login" />
                    <MenuItem id="registration" text="Zarejestruj się" href="/registration" />
                </>}
        </NavbarWrapper>
    );
}

export default Navbar;
