import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import fileDownload from 'js-file-download';


export const generateInvoicesForLastMonth = async (transactions) => {
  const now = new Date();

  let totalEnergy = 0;
  let totalCost = 0;

  for (const transaction of transactions) {
    totalEnergy += transaction.fulfilled_energy_amount;
    totalCost += transaction.price * transaction.fulfilled_energy_amount;
  }
  console.log(totalCost)

  const combinedTransaction = {
    fulfilled_energy_amount: totalEnergy,
    price: totalEnergy === 0? 0:totalCost / totalEnergy,
  };

  const formattedDate = now.toISOString().split('.')[0] + 'Z'; // '2024-08-10T14:00:00Z'
  await generateInvoice(combinedTransaction, formattedDate);
};

export const generateInvoice = async (transaction, end_time) => {
  console.log(transaction, end_time);
  const url = 'template.pdf';
  const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

  const ubuntuFontBytes = await fetch('font.ttf').then(res => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  pdfDoc.registerFontkit(fontkit);

  const helveticaFont = await pdfDoc.embedFont(ubuntuFontBytes);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  const { width, height } = firstPage.getSize();
  
  // Fill in the PDF with transaction data
  firstPage.drawText(end_time.split('T')[0], {
    x: 115,
    y: height - 35,
    size: 7,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  
  firstPage.drawText(end_time.split('T')[0], {
    x: 380,
    y: height - 45,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  
  firstPage.drawText("przelew", {
    x: 320,
    y: height - 60,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  
  firstPage.drawText("1.    Opłata za energię", {
    x: 55,
    y: height - 320,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  
  console.log(transaction.fulfilled_energy_amount.toString());
  
  firstPage.drawText(transaction.fulfilled_energy_amount.toString(), {
    x: 325,
    y: height - 320,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  
  firstPage.drawText("kWh", {
    x: 355,
    y: height - 320,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  
  firstPage.drawText(parseFloat(transaction.price.toString()).toFixed(2), {
    x: 410,
    y: height - 320,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  
  let sum = parseFloat((transaction.price * transaction.fulfilled_energy_amount).toString()).toFixed(2);
  firstPage.drawText(sum, {
    x: 470,
    y: height - 320,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  
  firstPage.drawText("23%", {
    x: 525,
    y: height - 320,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  
  firstPage.drawText("23%", {
    x: 250,
    y: height - 435,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  
  firstPage.drawText(sum, {
    x: 315,
    y: height - 435,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  
  let vat = parseFloat((parseFloat(sum) * 0.23).toString()).toFixed(2);
  firstPage.drawText(vat, {
    x: 425,
    y: height - 435,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  
  let brutto = parseFloat((parseFloat(sum) * 1.23).toString()).toFixed(2);
  firstPage.drawText(brutto, {
    x: 490,
    y: height - 435,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(sum, {
    x: 315,
    y: height - 460,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(vat, {
    x: 425,
    y: height - 460,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(brutto, {
    x: 490,
    y: height - 460,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText("0.00", {
    x: 500,
    y: height - 487,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(brutto, {
    x: 490,
    y: height - 515,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  let dziesietne = (parseFloat(brutto) - parseInt(brutto)).toFixed(2);
  firstPage.drawText(slowa(parseInt(brutto)) + " i " + dziesietne + " PLN", {
    x: 300,
    y: height - 540,
    size: 12,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  const pdfBytes = await pdfDoc.save();
  fileDownload(pdfBytes, "faktura.pdf");
};

function slowa(liczba) {
  var jednosci = ["", " jeden", " dwa", " trzy", " cztery", " pięć", " sześć", " siedem", " osiem", " dziewięć"];
  var nascie = ["", " jedenaście", " dwanaście", " trzynaście", " czternaście", " piętnaście", " szesnaście", " siedemnaście", " osiemnaście", " dziewietnaście"];
  var dziesiatki = ["", " dziesięć", " dwadzieścia", " trzydzieści", " czterdzieści", " pięćdziesiąt", " sześćdziesiąt", " siedemdziesiąt", " osiemdziesiąt", " dziewięćdziesiąt"];
  var setki = ["", " sto", " dwieście", " trzysta", " czterysta", " pięćset", " sześćset", " siedemset", " osiemset", " dziewięćset"];
  var grupy = [
    ["", "", ""],
    [" tysiąc", " tysiące", " tysięcy"],
    [" milion", " miliony", " milionów"],
    [" miliard", " miliardy", " miliardów"],
    [" bilion", " biliony", " bilionów"],
    [" biliard", " biliardy", " biliardów"],
    [" trylion", " tryliony", " trylionów"]
  ];

  var wynik = '';
  var znak = '';
  if (liczba === 0)
    wynik = "zero";
  if (liczba < 0) {
    znak = "minus";
    liczba = -liczba;
  }

  var g = 0;
  while (liczba > 0) {
    var s = Math.floor((liczba % 1000) / 100);
    var n = 0;
    var d = Math.floor((liczba % 100) / 10);
    var j = Math.floor(liczba % 10);

    if (d === 1 && j > 0) {
      n = j;
      d = 0;
      j = 0;
    }
    var k = 2;
    if (j === 1 && s + d + n == 0)
      k = 0;
    if (j === 2 || j === 3 || j === 4)
      k = 1;
    if (s + d + n + j > 0)
      wynik = setki[s] + dziesiatki[d] + nascie[n] + jednosci[j] + grupy[g][k] + wynik;
    g++;
    liczba = Math.floor(liczba / 1000);
  }
  return znak + wynik;
}
