import TransactionsList from "./TransactionsList";

import EnergymeterDetails from "./EnergymeterDetails";
import Balance from "./Balance"
import { ProfileWrapper } from "./ProfileWrapper";
import AccountDetails from "./AccountDetails";
import { FinanceInfoWrapper } from "./FinanceInfoWrapper";
import { AccountInfoWrapper } from "./AccountInfoWrapper";
import { useSelector } from "react-redux";
import { selectedPpeDataSelector } from "../MyPPE/selectors";
import { profileSelector } from "./selector";
import { parseDate } from "../../utils";

function Profile() {
    const data = useSelector(profileSelector);
    const profileData = data.profile
    const ppeData = useSelector(selectedPpeDataSelector);
    const transfersData = data.transfers;
    return (
        <ProfileWrapper>
            <AccountInfoWrapper>
                <div>Dane użytkownika</div>
                <hr></hr>
                <AccountDetails first_name={profileData.first_name} last_name={profileData.last_name} email={profileData.email} username={profileData.username} address={profileData.address} registrationDate={parseDate(profileData.date_joined)} companyName={profileData.company_name} nip={profileData.nip} bank_account_number={profileData.bank_account_number}/>
            </AccountInfoWrapper>
            <FinanceInfoWrapper>
                <div>Licznik</div>
                    <hr></hr>
                    <EnergymeterDetails state={ppeData.energymeter} blocked_capacity={ppeData.blocked_capacity} capacity={ppeData.capacity} />
                <div>Finanse</div>
                <hr></hr>
                <Balance balance={profileData.balance} blocked_balance={profileData.blocked_balance} />
                <TransactionsList transactions={transfersData} />
            </FinanceInfoWrapper>
        </ProfileWrapper>
    )
}

export default Profile;