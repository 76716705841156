import { BASE_URL } from "../../utils";
import { EMPTY_AUCTIONS, EMPTY_OFFERS } from "./const";

export const getAuctions = async (token) => {
    try {
        const response = await fetch(`${BASE_URL}/auctions/detailed`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token.token}`
            }
        });
            
        if (!response.ok) {
            console.log(response)
            return EMPTY_AUCTIONS
        }

        return response.json();
    } catch (error) {
        console.log(error)
        // TODO
    }
};

export const getOffers = async (auctionId, ppeId, token) => {
    try {
        const response = await fetch(`${BASE_URL}/user/offers/?ppe_id=${ppeId}&auction_id=${auctionId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token.token}`
            }
        });

        console.log(response)
    
        if (!response.ok) {
            console.log(response)
            return EMPTY_OFFERS
        }

        return response.json();
    } catch (error) {
        console.log(error)
        // TODO
    }
};

export const placeOffer = async (offer, token, ppe) => {
    try {
        offer.ppe_id = ppe;
        const response = await fetch(`${BASE_URL}/user/offers/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token.token}`
            },
            body: JSON.stringify(offer)
        });

        if (!response.ok) {
            const errorData = await response.json().catch(() => null);
            const errorMessage = errorData ? (errorData.message) : response.statusText;
            return { success: false, errorMessage };
        }
        
        const result = await response.json();
        return { success: true, result };
    } catch (error) {
        return { success: false, errorMessage: error.message };
    }
};


export const deleteOffer = async (offer_id, token) => {
    try {
        console.log(offer_id)
        const response = await fetch(`${BASE_URL}/user/offers/?offer_id=${offer_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token.token}`
            }
        });

        if (!response.ok) {
            console.log(response)
            // TODO
        }

        if (response.status === 204) {
            return null;
        }
        
        return response.json();
    } catch (error) {
        console.log(error)
        // TODO
    }
};