import { parseBankAccountNumber, parseMoney } from "../../../utils";
import { BalanceWrapper } from "./BalanceWrapper";

interface BalanceProps {
    balance: number;
    blocked_balance: number;
}

function Balance(props: BalanceProps) {
    return (
        <div>
            <BalanceWrapper>
                Saldo: {parseMoney(props.balance)} zł
            </BalanceWrapper>
            <BalanceWrapper>
                Zablokowane saldo: {parseMoney(props.blocked_balance)} zł
            </BalanceWrapper>
        </div>
    )
}

export default Balance;