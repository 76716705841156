import styled from 'styled-components';
import { theme } from 'styled-tools';
export const LoginWrapper = styled.form`
    width: 50%;
    margin-top: ${theme('dims.bigSpacing')};
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: ${theme('dims.bigSpacing')};
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    color: ${theme('colors.panel_text')};
    background: linear-gradient(180deg, ${theme('colors.panel_background')}, ${theme('colors.second_panel_background')});
    input[type=text], input[type=password] {
        width: 90%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        box-sizing: border-box;
    };
    button {
        width: 90%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        background-color: ${theme('colors.button_background')}
    };
    button:hover {
        background-color: ${theme('colors.button_hover_background')}
    }
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px    13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;