import styled from 'styled-components';
import { theme } from 'styled-tools';

export const ChartWrapper = styled.div`
    width: 100%;
    height: 100%;
    color: ${theme('colors.text')};

    .line {
        stroke: ${theme('colors.chart_line')};
    }
`;