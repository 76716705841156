
import { TransactionProps } from "./DetailedTransaction";
import DetailedTransaction from "./DetailedTransaction"
import { TransactionsListWrapper } from "./TransactionsListWrapper";

interface TransactionsListProps {
    transactions: TransactionProps[]
}

function DetailedTransactionsList(props: TransactionsListProps) {
    return (
        <TransactionsListWrapper>
            <div className="transactions-list-header">
                Transakcje
            </div>
            {props.transactions.map(transaction => (
                <DetailedTransaction key={'transaction-item' + transaction.id} id={transaction.id} date={transaction.date} amount={transaction.amount} type={transaction.type} />
            ))}
        </TransactionsListWrapper>
    )
}

export default DetailedTransactionsList;