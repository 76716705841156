import { theme } from 'styled-tools';
import React from "react";
import styled from "styled-components";
import { parseDate, parseMoney } from '../../utils';
import {generateInvoice} from './generateInvoice.js';

const HistoryAuctionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme('dims.basicSpacing')};
  margin: ${theme('dims.bigSpacing')};
  border-radius: 5px;
  text-align: center;
  color: ${theme('colors.panel_text')};
  background: linear-gradient(180deg, ${theme('colors.panel_background')}, ${theme('colors.second_panel_background')});
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px    13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

const Title = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ParameterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ParameterItem = styled.div`
  width: 48%;
  margin-bottom: 10px;
`;

const WynikSection = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const TransactionSection = styled.div`
  width: 100%;
  margin-bottom: 10px;
  margin-left: 250px;
`;

const LinkButton = styled.a`
  display: inline-block;
  padding: 8px 16px;
  margin-left: 5px;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
`;

const Button = styled.button`
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
`;

interface Transaction {
  id: number;
  type: string; // SELL/BUY
  declared_energy_amount: number;
  fulfilled_energy_amount: number;
  price: number;
  buyer_offer__auction__id: number;
}

export interface AuctionProps {
  id: number;
  price: number;
  volume: number;
  is_open: boolean;
  is_reserve: boolean;
  reserve_price: number;
  end_time: string;
  transactions: Transaction[];
  invoiceLink: string;
}

const Auction: React.FC<AuctionProps> = ({
  id,
  price,
  volume,
  is_open,
  is_reserve,
  reserve_price,
  end_time,
  transactions,
  invoiceLink,
}) => {
  return (
    <HistoryAuctionWrapper>
      <Title>Aukcja {id}</Title>
      <ParameterList>
        <ParameterItem>
          <div><b>Aukcja:</b> {id}</div>
          <div><b>Status:</b> {is_open ? "Otwarta" : "Zamknięta"}</div>
          <div><b>Otwarta:</b> {is_open ? "Tak" : "Nie"}</div>
          <div><b>Zamknięta:</b> {parseDate(end_time)}</div>
          {/* <div><LinkButton href="#">Aukcja rezerwowa</LinkButton></div> */}
        </ParameterItem>
        <ParameterItem>
          <div><b>Cena (PLN):</b> {price}</div>
          <div><b>Ilość (kWh):</b> {volume}</div>
          {/* <div><b>Aukcja rezerwowa:</b> {is_reserve ? "Tak" : "Nie"}</div> */}
          {/* <div><b>Cena rez. (PLN):</b> {reserve_price}</div> */}
        </ParameterItem>
      </ParameterList>
      <WynikSection>
        <div><b>Wynik:</b></div>
      
      {transactions.map((transaction) => ( transaction.buyer_offer__auction__id === id  &&
        <ParameterItem key={transaction.id}>
          <TransactionSection>
            {transaction.type === "BUY" ? "KUPNO" : "SPRZEDAŻ"} Zrealizowano : <b>{transaction.fulfilled_energy_amount} </b>/ {transaction.declared_energy_amount} kWh po cenie jednostkowej {transaction.price} zł za łączną kwotę {parseMoney(transaction.price * transaction.fulfilled_energy_amount)} zł
            <Button onClick={() => generateInvoice(transaction, end_time)}>Pobierz fakturę</Button>
          </TransactionSection>
        </ParameterItem>
      ))}
      </WynikSection>
    </HistoryAuctionWrapper>
  );
};

export default Auction;
