import DetailedPPE from "./DetailedPPE/index";
import { PPEProps } from "./DetailedPPE/index";



interface PPEsListProps {
    PPEs: PPEProps[]
}

function DetailedPPEsList(props: PPEsListProps) {
    const list = props.PPEs.map(ppe => {
        return (
            <DetailedPPE
                key={ppe.id}
                id={ppe.id}
                address={ppe.address}
                capacity={ppe.capacity}
                tariff_group={ppe.tariff_group}
                operator_name={ppe.operator_name}
                meter_number={ppe.meter_number}
                energymeter={ppe.energymeter}
                balance_unit={ppe.balance_unit}
                blocked_capacity={ppe.blocked_capacity}
                status={ppe.status}
                total_sold={ppe.total_sold}
                total_bought={ppe.total_bought}
            />
        );
    });

    return (
        <div>
            {list}
        </div>
    );
}

export default DetailedPPEsList;
