import React from "react";
import { useNavigate } from "react-router-dom";

const RegistrationSuccess = () => {
    const navigate = useNavigate();

    const redirectToLogin = () => {
        navigate('/login');  // Przekierowanie do strony logowania
    };

    return (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
            <h2>Rejestracja zakończona sukcesem!</h2>
            <p>Sprawdź swoją skrzynkę e-mailową, aby zweryfikować konto.</p>
            <button onClick={redirectToLogin}>Przejdź do logowania</button>
        </div>
    );
};


export default RegistrationSuccess;
