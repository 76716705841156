import { createSelector } from 'reselect';
import { prop } from 'ramda';

import { REGISTER_REDUCER_NAME } from './reducer';
import { NAVIGATION_REDUCER_NAME} from "./navigationReducer";

const getRegisterReducerState = prop(REGISTER_REDUCER_NAME);
const getNavigationReducerState = prop(NAVIGATION_REDUCER_NAME)

export const registerDetailsSelector = createSelector(
    getRegisterReducerState,
    (act_state) => act_state.get('registerDetails')
);

export const navigationSelector = createSelector(
    getNavigationReducerState,
    (act_state) => act_state.get('path')
);