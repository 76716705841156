import { fromJS } from 'immutable';
import { SET_HELPERS } from './const';
export const HELPERS_REDUCER_NAME = 'Helpers';

const initialState = fromJS({
    helpers: []
})

export const helpersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_HELPERS: {
            const { helpers } = action;
            console.log('Reducer: setting helpers to:' + helpers)
            return state.set("helpers", helpers)
        }
        default:
            return state;
    }
}