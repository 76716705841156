import { fromJS } from 'immutable';
import { SET_GLOBAL_ERROR, CLEAR_GLOBAL_ERROR } from './const';
export const ERROR_REDUCER_NAME = 'Error';

const initialState = fromJS({
    message: ''
})

export const errorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GLOBAL_ERROR: {
            const { message } = action;
            return state.set("message", message)
        }
        case CLEAR_GLOBAL_ERROR: {
            return state.set("message", '')
        }
        default:
            return state;
    }
}