import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAuctionNeedsUpdate } from '../actions';
import {AuctionItemWrapper} from './AuctionItemWrapper';
import AuctionData from './AuctionData';
import {ExpandButton} from './ExpandButton';
import AuctionOffers from './AuctionOffers';
import { AuctionStatus } from './AuctionData/AuctionStatus';

interface AuctionProps {
    id: string;
    startTime: string;
    endTime: string;
    tradeTime: string;
    status: AuctionStatus; // Assuming status is a string, adjust if it's different
}

function Auction(props: AuctionProps) {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = useCallback(() => {
        if (!expanded) {
            dispatch(setAuctionNeedsUpdate(props.id));
        }
        setExpanded((prevExpanded) => !prevExpanded);
    }, [dispatch, expanded, props.id]); 
    
    return (
        <AuctionItemWrapper>
            <AuctionData startTime={props.startTime} endTime={props.endTime} tradeTime={props.tradeTime} status={props.status} id={props.id} />
            {props.status === AuctionStatus.Open &&
            <ExpandButton type="button" onClick={toggleExpand}>
                {expanded ? '▲' : '▼'}
            </ExpandButton>
            }
            {expanded && (
                <div className="auction-offers-list">
                    <AuctionOffers auctionId={props.id} />
                </div>
            )}
        </AuctionItemWrapper>
    );
}

export default Auction;
