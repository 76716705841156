import { theme } from 'styled-tools';
import React from "react";
import styled from "styled-components";

// Styled component for the container
const HelpersWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme('dims.basicSpacing')};
  margin: ${theme('dims.bigSpacing')};
  border-radius: 5px;
  text-align: center;
  color: ${theme('colors.panel_text')};
  background: linear-gradient(180deg, ${theme('colors.panel_background')}, ${theme('colors.second_panel_background')});
`;


// Styled component for the title
const Title = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

// Styled component for the parameter list
const ParameterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

// Styled component for individual parameter item
const ParameterItem = styled.div`
  width: 48%;
  margin-bottom: 10px;
`;

// Styled component for the Wynik section
const WynikSection = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;



// Styled component for links
const LinkButton = styled.a`
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
`;


export interface HelperProps {
  helper_name: string; // Helper name
  user: string;
  kierunek: 'kupuj' | 'sprzedaj'; // Direction: "kupuj" (buy) or "sprzedaj" (sell)
  prices: number[]; // Array of 24 prices for each hour of the day
  days_of_week: boolean[]; // Day of the week (0-6, where 0 is Sunday)
  priority: number; // Helper priority
  energy_amount_kwh: number[]; // Energy amount in kilowatt-hours (kWh)
  // Add other necessary or useful properties
}

const props: HelperProps = {
  helper_name: 'Helper Name',
  user: 'User',
  kierunek: 'kupuj',
  prices: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220, 230, 240],
  days_of_week: [true, false, true, false, true, false, true],
  priority: 5,
  energy_amount_kwh: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2300, 2400]
};

const HelpersWrapper12: React.FC<HelperProps> = ({
  helper_name,
  user,
  kierunek,
  prices,
  days_of_week,
  priority,
  energy_amount_kwh
  }) => {
  const [editedPrices, setEditedPrices] = React.useState<number[]>(props.prices);
  const [editedEnergyAmounts, setEditedEnergyAmounts] = React.useState<number[]>(props.energy_amount_kwh);
  const [editedDaysOfWeek, setEditedDaysOfWeek] = React.useState<boolean[]>(props.days_of_week);
  const [editedPriority, setEditedPriority] = React.useState<number>(props.priority);

  const handlePriceChange = (index: number, value: number) => {
    const newPrices = [...editedPrices];
    newPrices[index] = value;
    setEditedPrices(newPrices);
  };

  const handleEnergyAmountChange = (index: number, value: number) => {
    const newEnergyAmounts = [...editedEnergyAmounts];
    newEnergyAmounts[index] = value;
    setEditedEnergyAmounts(newEnergyAmounts);
  };

  const handleDayOfWeekToggle = (index: number) => {
    const newDaysOfWeek = [...editedDaysOfWeek];
    newDaysOfWeek[index] = !editedDaysOfWeek[index];
    setEditedDaysOfWeek(newDaysOfWeek);
  };

  const handlePriorityChange = (value: number) => {
    const newPriority = editedPriority;
    setEditedPriority(newPriority);
  };

  return (
    <HelpersWrapper1>
      {/* Title */}
      <Title>Nazwa: {props.helper_name}</Title>
      {/* Parameter list */}
      <ParameterList>
        {/* Left column */}
        <ParameterItem>
          <div><b>Kierunek: {props.kierunek}</b></div>
          <div><b>Cena [zł/kWh dla kolejnych godzin doby]:</b></div>
          {editedPrices.map((price, index) => (
            <div key={index}>
              <span>{index < 10 ? `0${index}` : index}: </span>
              <input type="number" value={price} onChange={(e) => handlePriceChange(index, parseInt(e.target.value))} />
            </div>
          ))}
          <div><b>Dni tygodnia:</b></div>
          {editedDaysOfWeek.map((day, index) => (
            <div key={index}>
              <span>{['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'][index]}:</span>
              <input type="checkbox" checked={day} onChange={() => handleDayOfWeekToggle(index)} />
            </div>
          ))}
          <div><b>Ilość energii [kWh dla kolejnych godzin doby]:</b></div>
          {editedEnergyAmounts.map((amount, index) => (
            <div key={index}>
              <span>{index < 10 ? `0${index}` : index}: </span>
              <input type="number" value={amount} onChange={(e) => handleEnergyAmountChange(index, parseInt(e.target.value))} />
            </div>
          ))}
        </ParameterItem>
        {/* Right column */}
        <ParameterItem>
          <div><b>Pomocnik2</b></div>
        </ParameterItem>
      </ParameterList>
      {/* Wynik */}
      <WynikSection>
        <div><b>Wynik:</b></div>
        {/* Transactions */}
      </WynikSection>
    </HelpersWrapper1>
  );
};


export default HelpersWrapper12;