import { BASE_URL } from "../../utils";

export const getTokenFromRegister = async (registerDetails) => {
    try {
        const response = await fetch(`${BASE_URL}/auth/register/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(registerDetails)
        });

        if (!response.ok) {
            const errorData = await response.json().catch(() => null);
            let errorMessage = 'Registration failed';
            if (errorData) {
                errorMessage = Object.entries(errorData)
                    .map(([field, errors]) => `${field}: ${errors.join(', ')}`)
                    .join('; ');
            } else {
                errorMessage = response.statusText;
            }
            return { success: false, errorMessage };
        }

        const result = await response.json();
        return {
            success: true,
            message: "A confirmation email has been sent. Please check your inbox to verify your account."
        };
    } catch (error) {
        return { success: false, errorMessage: error.message };
    }
};

// Developed to help users verify their account, not used right now.
// export const verifyEmail = async (uid, token) => {
//     try {
//         const response = await fetch(`${BASE_URL}/auth/activate/${uid}/${token}/`, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//
//         if (!response.ok) {
//             return { success: false, errorMessage: "Invalid or expired activation link." };
//         }
//
//         return { success: true, message: "Your account has been successfully verified!" };
//     } catch (error) {
//         return { success: false, errorMessage: error.message };
//     }
// };