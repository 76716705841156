import { BASE_URL } from "../../utils";

export const logoutUser = async (token) => {
    try {
        const response = await fetch(`${BASE_URL}/auth/logout/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token.token}`
            },
        });

        if (response.ok) {
            console.log(response);
            // Additional cleanup actions can be dispatched here
        }
    } catch (error) {
        console.error('Logout error:', error);
        // Dispatch any error handling actions here
    }
};