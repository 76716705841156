import { ofType } from 'redux-observable';
import { map, switchMap, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { SET_TOKEN } from '../Login/const';
import { tokenSelector } from '../Login/selectors';
import { getAuctions, placeOffer } from './api';
import { setAuctions, setOffersForAuction } from './actions';
import { getOffers, deleteOffer } from './api';
import { DELETE_OFFER, SET_AUCTION_NEEDS_UPDATE, SET_PLACED_OFFER } from './const';
import { setAuctionNeedsUpdate } from './actions';
import { setProfileNeedsUpdate } from '../Profile/actions';
import { of } from 'rxjs';
import { selectedPpeSelector } from '../MyPPE/selectors';
import { setGlobalError } from '../Error/actions';

export const updateAuctionsEpic = (action$, state$) =>
    action$.pipe(
        ofType(SET_TOKEN),
        map(() => ({
            token: tokenSelector(state$.value)
        })),
        switchMap(({token}) =>
        from(getAuctions(token)).pipe(map(( auctions ) => setAuctions(auctions))))
    )

export const fetchOffersForAuctionEpic = (action$, state$) =>
  action$.pipe(
      ofType(SET_AUCTION_NEEDS_UPDATE),
      map((action) => ({
        key: action.key,
        ppe: selectedPpeSelector(state$.value),
        token: tokenSelector(state$.value)
      })),
      switchMap(({key,ppe,token}) =>
      from(getOffers(key, ppe, token)).pipe(
          map(offers => setOffersForAuction(key, offers))))
  );

export const postOfferEpic = (action$, state$) =>
  action$.pipe(
    ofType(SET_PLACED_OFFER),
    map((action) => ({
      token: tokenSelector(state$.value),
      offer: action.offer,
      ppe: selectedPpeSelector(state$.value)
    })),
    switchMap(({token, offer, ppe}) =>
      from(placeOffer(offer, token, ppe)).pipe(
        mergeMap(({success, errorMessage}) => success ? 
          of(
            setAuctionNeedsUpdate(offer.auction_id),
            setProfileNeedsUpdate()
          ) :
          of(setGlobalError(errorMessage))
        )
      )
    )
  );

export const deleteOfferEpic = (action$, state$) =>
action$.pipe(
    ofType(DELETE_OFFER),
    map((action) => ({
        token: tokenSelector(state$.value),
        offer_id: action.offer_id,
        auction_id: action.auction_id
    })),
    switchMap(({token, offer_id, auction_id}) =>
    from(deleteOffer(offer_id, token)).pipe(map(( isOK ) =>  setAuctionNeedsUpdate(auction_id))))
);