import { TransactionType } from "./components/Wallet/DetailedTransactionsList/DetailedTransaction/TransactionType"

export const BASE_URL = process.env.REACT_APP_API_URL || "https://perun.hexanity.co:80"

export function parseDate(date: string): string {
    date = date.substring(0, date.length - 1) // Cut the 'Z' from ISO8601 format
    date = date.split('.')[0] // Cut the milliseconds
    date = date.replace('T', ' ') // Replace 'T' with a space for better readibility

    return date
}

export function parseBankAccountNumber(number: string): string {
    let country_code = number.substring(0, 2)
    let account_number = number.substring(2, 26)
    account_number = account_number.replace(/(.{4})/g, '$1 ').trim(); // Add spaces
    return country_code + " " + account_number 
}

export function parseMoney(number: number): string {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") // Spaces as decimal separators
}

// TODO - other types
export function parseTransactionType(type: TransactionType) {
    switch(type) {
        case TransactionType.Deposit:
            return "Wpłata środków"
        case TransactionType.Withdrawal:
            return "Wypłata środków"
        case TransactionType.Credit:
            return "Zakup na aukcji"
        case TransactionType.Debit:
            return "Sprzedaż na aukcji"
    }
}

export function parseUserType(type: string) {
    switch(type) {
        case "PROSUMER":
            return "Prosument"
        case "PRODUCER": 
            return "Producent"
        case "CONSUMER":
            return "Konsument"
    }
}

// 2024-02-21T14:07:25Z
export function parseDateShort(date: string) {
    return date.split("T")[1].substring(0, 5)
}

export const EMPTY_PROFILE = {
    "username": "",
    "email": "",
    "first_name": "",
    "last_name": "",
    "bank_account_number": "",
    "energymeter": 0,
    "blocked_balance": 0,
    "balance": 0,
    "blocked_capacity": 0,
    "capacity": 0,
    "address": "",
    "date_joined": "",
    "company_name": "",
    "nip": "",
    "is_reserve": false,
    "user_type": ""
}

export const EMPTY_TRANSFERS = []
