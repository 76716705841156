import styled from 'styled-components';
import { theme } from 'styled-tools';

export const PlaceOfferWrapper = styled.form`
    display: flex;
    flex-direction: column;
    text-align: left;

    border-radius: 5px;
    color: ${theme('colors.panel_text')};
`;

export const PlaceOfferRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    text-align: left;

    border-radius: 5px;
    color: ${theme('colors.panel_text')};
`;

export const AddHelperButton = styled.button`
    border-radius: 5px;
    text-align: center;
    border-style: none;
    background-color: ${theme('colors.button_background')};
    padding: ${theme('dims.basicSpacing')};
    color: ${theme('colors.text')};
    font-size: 32px;
    &:hover {
        background-color: ${theme('colors.button_hover_background')};
    }
`;

export const AutoFillButton = styled.button`
  border-radius: 5px;
  text-align: center;

  border-style: solid;
  border-width: 2px;
  border-color: ${theme('colors.button_background')};

  padding: ${theme('dims.basicSpacing')};
  color: ${theme('colors.button_background')};

  background-color: ${theme('colors.button_background_2')};
  
  margin-right: ${theme('dims.smallSpacing')};
  &:hover {
    background-color: ${theme('colors.button_hover_background_2')};
    border-color: ${theme('colors.border_hover')}; // Optionally change the border color on hover
  }
`;

export const Label = styled.label`
    border-radius: 5px;
    border-style: none;
    margin: ${theme( 'dims.smallSpacing')};
`;

export const Input = styled.input`
    border-radius: 5px;
    border-style: none;
    padding: ${theme( 'dims.smallSpacing')};
    margin: ${theme( 'dims.smallSpacing')};
`;