import DetailedTransactionsList from "./DetailedTransactionsList";

import WalletDetails from "./WalletDetails";
import { WalletWrapper } from "./WalletWrapper";
import { FinanceInfoWrapper } from "./FinanceInfoWrapper";
import { useSelector } from "react-redux";
import { profileSelector } from "../Profile/selector";

function Wallet() {
    const data = useSelector(profileSelector);
    const profileData = data.profile
    const transfersData = data.transfers;

    return (
        <WalletWrapper>
            <FinanceInfoWrapper>
                <div>Finanse</div>
                <hr></hr>
                <WalletDetails balance={profileData.balance} blocked_balance={profileData.blocked_balance} bank_account_number={profileData.bank_account_number}/>
            </FinanceInfoWrapper>
            <FinanceInfoWrapper style={{'width': '60%'}}>
                <div>Transakcje</div>
                    <hr></hr>
                <DetailedTransactionsList transactions={transfersData} />
            </FinanceInfoWrapper>
        </WalletWrapper>
    )
}

export default Wallet;