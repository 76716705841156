import { combineReducers } from "redux";

import { LOGIN_REDUCER_NAME, loginReducer } from "./components/Login/reducer";
import { AUCTIONS_REDUCER_NAME, auctionsReducer } from "./components/AuctionMenu/reducer";
import { REGISTER_REDUCER_NAME, registerReducer } from "./components/Registration/reducer";
//import { OFFERS_REDUCER_NAME, offersReducer } from "./components/History/OffersList/reducer";
import { PROFILE_REDUCER_NAME, profileReducer } from "./components/Profile/reducer";
import { HELPERS_REDUCER_NAME, helpersReducer } from "./components/Helpers/reducer";
import { ERROR_REDUCER_NAME, errorReducer } from "./components/Error/reducer";
import { PPE_REDUCER_NAME, ppeReducer } from "./components/MyPPE/reducer";
import { NAVIGATION_REDUCER_NAME, navigationReducer} from "./components/Registration/navigationReducer";

export function createReducer() {
    return combineReducers({
        [LOGIN_REDUCER_NAME]: loginReducer,
        [REGISTER_REDUCER_NAME]: registerReducer,
        [AUCTIONS_REDUCER_NAME]: auctionsReducer,
        [HELPERS_REDUCER_NAME]: helpersReducer,
        //[OFFERS_REDUCER_NAME]: offersReducer,
        [PROFILE_REDUCER_NAME]: profileReducer,
        [ERROR_REDUCER_NAME]: errorReducer,
        [PPE_REDUCER_NAME]: ppeReducer,
        [NAVIGATION_REDUCER_NAME] : navigationReducer
    });
}