import styled from 'styled-components';
import { theme } from 'styled-tools';

export const DeleteButton = styled.button`
  border-radius: 5px;
  text-align: center;

  border-style: solid;
  border-width: 2px;
  border-color: ${theme('colors.button_background')};

  padding: 8px;
  color: ${theme('colors.button_background')};

  background-color: ${theme('colors.button_background_2')};
  
  margin-right: ${theme('dims.smallSpacing')};
  &:hover {
    background-color: ${theme('colors.button_hover_background_2')};
    border-color: ${theme('colors.border_hover')}; // Optionally change the border color on hover
  }
`;