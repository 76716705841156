export const darkColors = {
    background: '#222831', // Cream white for main background
    second_background: '#222831', // Light green for secondary background
    button_background: '#007bff', // Soft green for button backgrounds
    button_hover_background: '#52A3FF', // Slightly darker green for button hover
    button_background_2: '#2B323C', // Very light cream for alternate buttons
    error_background: '#E32636', // Red for error button
    button_hover_background_2: '#404752', // Slightly darker cream for button hover
    panel_background: '#393E46', // Light cream for panel backgrounds
    second_panel_background: '#393E46', // Light greenish cream for secondary panels
    highlight: '#9BC1B2', // Soft green for highlights
    error_text: '#FFFFFF',
    text: '#EEEEEE', // Dark green for text
    panel_text: '#EEEEEE', // Darker green for panel text
    text_highlight: '#7AA891', // Lighter green for text highlights
    panel_text_highlight: '#6D8470', // Soft green for panel text highlights
    border: '#8FAA8D', // Soft green for borders
    item_background: '#F7F6F2', // Cream white for item background
    second_item_background: '#E3E8E3', // Light greenish cream for secondary items
    button_delete: '#D3E4D3', // Soft green, using green instead of red for delete buttons
    button_delete_hover: '#BEC9BE', // Darker green for delete button hover
    button_edit: '#C4D7C3', // Soft green for edit buttons
    button_edit_hover: '#AEBFAE', // Slightly darker green for edit button hover
    chart_line: '#DDE5DD' // Light green for chart lines
}

export const lightColors = {
    background: '#02353C',
    second_background: '#449342',
    button_background: '#006666',
    button_hover_background: '#007676',
    button_background_2: '#F0F0F0',
    error_background: '#E32636',
    button_hover_background_2: '#E0E0E0',
    panel_background: '#ADBFAD',
    second_panel_background: '#CFD2CF',
    highlight: '#3FD0C9',
    error_text: '#FFFFFF',
    text: '#C1F6ED',
    panel_text: '#1B291B',
    text_highlight: '#5DE2CC',
    panel_text_highlight: '#5D795D',
    border: '#2EAF79',
    item_background: '#8F9B8F',
    second_item_background: '#CFE1B9',
    button_delete: '#ff0000',
    button_delete_hover: '#ff6666',
    button_edit: '#ffad33',
    button_edit_hover: '#ffc266',
    chart_line: '#CFD2CF'
}