import React, { useState } from 'react';

import { AuctionMenuWrapper, DatePickerWrapper, StyledDatePicker, DataChoiceLabel } from './styles';
import { useSelector } from 'react-redux';
import Auction from './Auction';
import { AuctionStatus } from './Auction/AuctionData/AuctionStatus';
import { auctionsSelector } from './selectors';
import Chart from './Chart';
import UserInfo from '../UserInfo';
import { profileSelector } from '../Profile/selector';

function AuctionMenu() {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const auctions = useSelector(auctionsSelector);
    const profileData = useSelector(profileSelector).profile;

    let filteredAuctions = auctions.filter(auction => {
        const auctionDate = new Date(auction.end_time);
        return (selectedDate.toISOString().split("T")[0] === auctionDate.toISOString().split("T")[0]);
    });
    return (
        <AuctionMenuWrapper>
            <div className='userinfo'>
                <div className="auctions-list-header">
                    Dane użytkownika
                </div>
                <hr></hr>
                <UserInfo {...profileData} />
            </div>
            <div className="chart">
                <Chart />
            </div>
            {/* <div className="helper">
                <Helper />
            </div> */}

            <DatePickerWrapper>
                <DataChoiceLabel>Data końca aukcji</DataChoiceLabel>
                <StyledDatePicker
                    selected={selectedDate}
                    onChange={date => setSelectedDate(date)}
                />
            </DatePickerWrapper>

            <div className="auctions-list">
                <div className="auctions-list-header">
                    Aukcje
                </div>
                <hr></hr>
                {filteredAuctions.map(auction => (
                    <Auction
                        key={'auction-item-' + auction.id}
                        id={auction.id}
                        startTime={auction.start_time}
                        endTime={auction.end_time}
                        tradeTime={auction.trade_time}
                        status={auction.is_open ? AuctionStatus.Open : AuctionStatus.Closed}
                    />
                ))}
            </div>
        </AuctionMenuWrapper>
    )
}

export default AuctionMenu;
