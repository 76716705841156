
import { TransactionProps } from "./Transaction";
import Transaction from "./Transaction"
import { TransactionsListWrapper } from "./TransactionsListWrapper";

interface TransactionsListProps {
    transactions: TransactionProps[]
}

function TransactionsList(props: TransactionsListProps) {

    const list = props.transactions.map((transaction => {
        return (
            <Transaction key={"Transaction" + transaction.id} id={transaction.id} date={transaction.date} amount={transaction.amount} type={transaction.type} />
        );
    }))

    return (
        <TransactionsListWrapper>
            <div className="transactions-list-header">
                Transakcje
            </div>
            {list}
        </TransactionsListWrapper>
    )
}

export default TransactionsList;