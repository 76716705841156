import {useState} from "react";
import { ReactNode } from "react";
import {CollapseButton} from "./styles";

interface Props {
    children?: ReactNode
}

const Collapsible = ({children}: Props) => {

    const [collapsed, setCollapsed] = useState(true)

    return (
        <>
            <CollapseButton onClick={() => setCollapsed(!collapsed)}> {collapsed ? "Rozwiń" : "Zwiń"} </CollapseButton>

            {/* Show if not collapsed */}
            {!collapsed &&
                <div>
                    {children}
                </div>
            }
        </>
    )
};


export default Collapsible;