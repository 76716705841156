import { fromJS } from 'immutable';
import { SET_AUCTIONS, SET_OFFERS_FOR_AUCTION, SET_HELPERS } from './const';
export const AUCTIONS_REDUCER_NAME = 'Auctions';

const initialState = fromJS({
    auctions: [],
    offers: {}
})

export const auctionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUCTIONS: {
            const { auctions } = action;
            console.log('Reducer: setting auctions to:' + auctions)
            return state.set("auctions", auctions)
        }
        case SET_OFFERS_FOR_AUCTION: {
            const { auction, offers } = action;
            console.log('Reducer: setting offers for ' + auction + ' to:', fromJS(offers));
            return state.setIn(['offers', auction], fromJS(offers));
        }
        case SET_HELPERS: {
            const { helpers } = action;
            console.log('Reducer: setting helpers to:' + helpers)
            return state.set("helpers", helpers)
        }
        default:
            return state;
    }
}