import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';

import { errorSelector } from './selectors';
import { clearGlobalError } from './actions';
import { ModalButton, ModalTitle, ModalDesc, globalErrorModalStyle } from './styles';

export const GlobalErrorModal = () => {
    const dispatch = useDispatch();
    const errorMessage = useSelector(errorSelector);
    const isOpen = Boolean(errorMessage);

    const closeModal = () => {
        dispatch(clearGlobalError());
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Error"
            style={globalErrorModalStyle}
        >
            <ModalTitle>Nieprawidłowa operacja</ModalTitle>
            <ModalDesc>{errorMessage}</ModalDesc>
            <ModalButton onClick={closeModal}>Zamknij</ModalButton>
        </Modal>
    );
};