import styled from 'styled-components';
import { theme } from 'styled-tools';

export const NavbarWrapper = styled.div`
    height: 4rem;
    font-size: 1.2rem;
    text-align: center;

    backdrop-filter: blur(20px);
    position: sticky;
    top: 0;

    display: grid;
    grid-template-areas:
      'aukcje historia timer balance profil';
    grid-template-columns: 2fr 1fr 1fr 1fr 6fr 4fr 2fr 2fr;
    grid-template-rows: 1fr;

    #aukcje { grid-area: aukcje; }
    #historia { grid-area: historia;}
    #timer { grid-area: timer; }
    #balance { grid-area: balance; }
    #profile { grid-area: profile; }
    #p1 { grid-area: p1; }

    margin-left: ${theme('dims.hugeSpacing')};
    margin-right: ${theme('dims.hugeSpacing')};

    z-index: 1;
`;

export const DropdownWrapper = styled.div`
  width: 200px;
  text-align: left;
  vertical-align: bottom;
  padding-top: 20px;

  .Dropdown-control {
    background-color: transparent;
    border: 1px solid ${theme('colors.border')};
    border-radius: 4px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: ${theme('colors.text')};
  }

  .Dropdown-menu {
    background-color: ${theme('colors.panel_background')};
    border: 1px solid ${theme('colors.border')};
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    margin-top: 5px;
  }

  .Dropdown-option {
    padding: 10px;
    &:hover {
      background-color: ${theme('colors.second_panel_background')};
    }
  }

  .Dropdown-arrow {
    transition: transform 0.2s ease-in-out;
  }

  .is-open .Dropdown-arrow {
    transform: rotate(180deg);
  }
`;