import { BASE_URL } from "../../utils";
import { EMPTY_HELPERS } from "./const";

export const getHelpers = async (token) => {
    try {
        const response = await fetch(`${BASE_URL}/moipomocnicy/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token.token}`
            }
        });
            
        if (!response.ok) {
            console.log(response)
            return EMPTY_HELPERS
        }

        return response.json();
    } catch (error) {
        console.log(error)
        // TODO
    }
};