import { BASE_URL } from "../../utils";

export const addPPE = async (token, ppe) => {
    try {
        const response = await fetch(`${BASE_URL}/user/ppe/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token.token}`
            },
            body: JSON.stringify(ppe)
        });

        if (!response.ok) {
            const errorData = await response.json().catch(() => null);
            const errorMessage = errorData ? (errorData.message) : response.statusText;
            return { success: false, errorMessage };
        }
        
        const result = await response.json();
        return { success: true, result };
    } catch (error) {
        return { success: false, errorMessage: error.message };
    }
};

export const getPPE = async(token) => {
    try {
        const response = await fetch(`${BASE_URL}/user/ppe/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token.token}`
            }
        });

        if (!response.ok) {
            console.log(response)
            return []
        }
        
        return response.json();
    } catch (error) {
        console.log(error)
        // TODO
    }
}