import { SET_TOKEN, SET_LOGIN_DETAILS, CLEAR_TOKEN } from "./const"

export const setLoginDetails = (username, password) => ({
    type: SET_LOGIN_DETAILS,
    username,
    password
})

export const setToken = (token) => ({
    type: SET_TOKEN,
    token
})

export const clearToken = () => ({
    type: CLEAR_TOKEN
})