import styled from 'styled-components';
import { theme } from 'styled-tools';

export const AccountNumberWrapper = styled.div`
    text-align: left;
    padding: ${theme('dims.basicSpacing')};
    margin: ${theme('dims.bigSpacing')};
    color: ${theme('colors.panel_text')};
    border-radius: 5px;
    background: linear-gradient(180deg, ${theme('colors.panel_background')}, ${theme('colors.second_panel_background')});
`;


export const WalletDetailsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    text-align: left;
    padding: ${theme('dims.bigSpacing')};
    border-radius: 5px;
    color: ${theme('colors.panel_text')};

`;

export const WalletFieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: ${theme('dims.bigSpacing')};
    border-radius: 5px;
    color: ${theme('colors.panel_text')};
    background: linear-gradient(180deg, ${theme('colors.panel_background')}, ${theme('colors.second_panel_background')});
    margin: ${theme('dims.smallSpacing')};
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px    13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

export const WalletFieldDataWrapper = styled.div`
    font-weight: bold;
    text-align: left;
`;

export const WalletFieldLabelWrapper = styled.div`
    font-size: 14px;
    text-align: left;
`;