import styled from 'styled-components';
import { theme } from 'styled-tools';

export const TransactionsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${theme('dims.basicSpacing')};
    margin: ${theme('dims.bigSpacing')};
    border-radius: 5px;
    color: ${theme('colors.panel_text')};
    background: linear-gradient(180deg, ${theme('colors.panel_background')}, ${theme('colors.second_panel_background')});
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px    13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;