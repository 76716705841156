import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from 'react-modal';
import { setPlacedOffer } from "../../../actions";
import { PlaceOfferWrapper, AddOfferButton, PlaceOfferRowWrapper, Input, Label, AutoFillButton, ModalLabel, ModalButton } from "./styles";

interface PlaceOfferProps {
    id: string;
}

function PlaceOffer(props: PlaceOfferProps) {
    const [amount, setAmount] = useState('3000');
    const [price, setPrice] = useState('1000');
    const [direction, setDirection] = useState('BUY');
    const [participateInReserveAuction, setParticipateInReserveAuction] = useState(false);
    const [reserveAuctionPrice, setReserveAuctionPrice] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const dispatch = useDispatch();

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const onAddOfferClick = useCallback((event: { preventDefault: () => void; }) => {
        event.preventDefault();
        openModal();
    }, []);

    const confirmOffer = () => {
        dispatch(setPlacedOffer({
            "auction_id": props.id,
            "energy_amount": amount,
            "price": price,
            "direction": direction,
            "participate_in_reserve_auction": participateInReserveAuction,
            "reserve_auction_price": participateInReserveAuction ? reserveAuctionPrice : undefined
        }));
        closeModal();
    };

    const fillInFieldsAutomatically = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setAmount('5000');
        setPrice('1500');
        setDirection('BUY');
        setParticipateInReserveAuction(true);
        setReserveAuctionPrice('1200');
    };

    return (
        <PlaceOfferWrapper>
            <PlaceOfferRowWrapper>
                <Label>Cena (pln)</Label>
                <Input type="number" id="price" pattern="[0-9]*" name="price" value={price} onChange={event => setPrice(event.target.value)} />
                <Label>Ilość (kWh)</Label>
                <Input type="number" id="amount" pattern="[0-9]*" name="amount" value={amount} onChange={event => setAmount(event.target.value)} />
            </PlaceOfferRowWrapper>
            <PlaceOfferRowWrapper>
                <Label>Kierunek</Label>
                <Input type="radio" id="buy" name="direction" value="BUY" checked={direction === 'BUY'} onChange={() => setDirection('BUY')} />
                <Label htmlFor="buy">Kup</Label>
                <Input type="radio" id="sell" name="direction" value="SELL" checked={direction === 'SELL'} onChange={() => setDirection('SELL')} />
                <Label htmlFor="sell">Sprzedaj</Label>
                {/* <Label htmlFor="participateInReserveAuction">Aukcja rezerwowa</Label>
                <input type="checkbox" id="participateInReserveAuction" checked={participateInReserveAuction} onChange={() => setParticipateInReserveAuction(!participateInReserveAuction)} />
                {participateInReserveAuction && (
                    <>
                        <Label htmlFor="reserveAuctionPrice">Cena (pln)</Label>
                        <Input type="number" id="reserveAuctionPrice" pattern="[0-9]*" name="reserveAuctionPrice" value={reserveAuctionPrice} onChange={(event) => setReserveAuctionPrice(event.target.value)} />
                    </>
                )} */}
                <AutoFillButton onClick={fillInFieldsAutomatically}>Wypełnij automatycznie</AutoFillButton>
                <AddOfferButton onClick={onAddOfferClick}>Złóż ofertę</AddOfferButton>
            </PlaceOfferRowWrapper>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Potwierdzenie oferty"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px    13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
                    }
                }}
            >
                <ModalLabel>Czy jesteś pewien, że chcesz złożyć ofertę?</ModalLabel>
                <ModalButton onClick={confirmOffer}>Tak</ModalButton>
                <ModalButton onClick={closeModal}>Nie</ModalButton>
            </Modal>
        </PlaceOfferWrapper>
    );
}

export default PlaceOffer;
