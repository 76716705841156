import { WalletDetailsWrapper, WalletFieldDataWrapper, WalletFieldLabelWrapper, WalletFieldWrapper } from "./WalletDetailsWrapper";
import { parseMoney, parseBankAccountNumber } from "../../../utils";

export interface WalletDetailsProps {
    balance: number;
    blocked_balance: number;
    bank_account_number: string;
}

function WalletDetails(props: WalletDetailsProps) {
    return (
        <div>
            <WalletDetailsWrapper>
                <WalletFieldWrapper>
                    <WalletFieldLabelWrapper>Numer konta</WalletFieldLabelWrapper>
                    <WalletFieldDataWrapper className="Wallet-details-field">
                        {parseBankAccountNumber(props.bank_account_number)}
                    </WalletFieldDataWrapper>
                </WalletFieldWrapper>
            </WalletDetailsWrapper>
            <WalletDetailsWrapper>
                <WalletFieldWrapper>
                    <WalletFieldLabelWrapper>Dostępne środki</WalletFieldLabelWrapper>
                    <WalletFieldDataWrapper className="Wallet-details-field">
                        {parseMoney(props.balance)} zł
                    </WalletFieldDataWrapper>
                </WalletFieldWrapper>
                <WalletFieldWrapper>
                    <WalletFieldLabelWrapper>Zablokowane środki</WalletFieldLabelWrapper>
                    <WalletFieldDataWrapper className="Wallet-details-field">
                        {parseMoney(props.blocked_balance)} zł
                    </WalletFieldDataWrapper>
                </WalletFieldWrapper>
            </WalletDetailsWrapper>
        </div>
    )
}

export default WalletDetails;