import styled from "styled-components";
import { theme } from "styled-tools";

export enum PPEType {
    Deposit = "DEPOSIT",
    Withdrawal = "WITHDRAWAL",
    Credit = "CREDIT",
    Debit = "DEBIT",
}

type PPEProps = {
    type?: PPEType;
};
  
export const Amount = styled.div<PPEProps>`
    margin-left: ${theme('dims.bigSpacing')};
    font-weight: bold;
    text-align: right;
    flex: 1;
    ${({ type }) => type === PPEType.Withdrawal && `
        color: #970909;
    `}
    ${({ type }) => type === PPEType.Deposit && `
        color: #156437;
    `}
`;

export const Value = styled.div`
    margin-left: ${theme('dims.smallSpacing')};
    font-weight: bold;
`

type StatusWrapperProps = {
    $status: string;
  };
  
export const StatusWrapper = styled.div<StatusWrapperProps>`
    display: flex;
    flex-direction: row;
    max-height: 25px;
    padding: ${theme('dims.smallSpacing')};
    margin-right: ${theme('dims.bigSpacing')};
    border-radius: 25px;
    color: ${theme('colors.panel_text')};
    background: ${({ $status }) => (
      $status === 'NONREGISTERED' ? '#D8415F' : // Red for NONREGISTERED
      ($status === 'REGISTERED' ? '#FFC107' : // Yellow for AREGISTERED
      ($status === 'ACTIVE' ? '#33955D' : 'transparent')) // Green for ACTIVE
    )};
    font-size: ${theme('fontSizes.small')};
    align-items: center;
    justify-content: center;
`;


export const PPEWrapper = styled.div<PPEProps>`
  display: flex;
  alignItems: center;
  justifyContent: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${theme('dims.smallSpacing')};  margin-left: ${theme('dims.smallSpacing')};
  margin-right: ${theme('dims.smallSpacing')};
  border-radius: 3px;
  color: ${theme('colors.panel_text')};
  background: linear-gradient(180deg, ${theme('colors.item_background')}, ${theme('colors.second_item_background')});
`;

export const PPEFieldWrapper = styled.div<PPEProps>`
  display: flex;
  flex-direction: row;
  flex: 50%;
  padding: 0.25em 0
`