import React from "react";
import { UserInfoWrapper, ParameterList, ParameterItem } from './UserInfoWrapper'
import { parseUserType } from "../../utils";

interface UserInfoProps {
  user_type: string;
  PPE: string;
  auto_reserve: string;
}

const UserInfo: React.FC<UserInfoProps> = (props: UserInfoProps) => {
  return (
    <UserInfoWrapper>
      <ParameterList>
        <ParameterItem>
          <div className="label">Profil:</div>
          <div className="value">{parseUserType(props.user_type)}</div>
        </ParameterItem>
        <ParameterItem>
          <div className="label">Punkt Poboru Energii:</div>
          <div className="value">{"DSFDSS3243"}</div>
        </ParameterItem>
        {/* <ParameterItem>
          <div className="label">Automatyczne uczestnictwo w aukcjach rezerwowych:</div>
          <div className="value">{props.auto_reserve ? "TAK" : "NIE"}</div>
        </ParameterItem> */}
      </ParameterList>
    </UserInfoWrapper>
  );
};

export default UserInfo;