import { theme } from "styled-tools";
import styled from "styled-components";


export const ModalButton = styled.button`
  border-radius: 5px;
  text-align: center;

  border-style: solid;
  border-width: 2px;
  border-color: ${theme('colors.error_background')};

  padding-top: ${theme('dims.basicSpacing')};
  padding-bottom: ${theme('dims.basicSpacing')};
  padding-right: ${theme('dims.hugeSpacing')};
  padding-left: ${theme('dims.hugeSpacing')};
  color: ${theme('colors.error_background')};

  background-color: ${theme('colors.button_background_2')};
  

  &:hover {
    background-color: ${theme('colors.button_hover_background_2')};
    border-color: ${theme('colors.border_hover')};
  }
`;

export const ModalTitle = styled.h2`
  border-radius: 5px;
  font-size: 26px;
  margin-top: ${theme('dims.smallSpacing')};
  padding-right: ${theme('dims.basicSpacing')};
  padding-left: ${theme('dims.basicSpacing')};
  margin-bottom: ${theme('dims.basicSpacing')};
  margin-top: ${theme('dims.basicSpacing')};
  color: ${theme('colors.error_text')};

`;

export const ModalDesc = styled.div`
  border-radius: 5px;

  margin-top: ${theme('dims.smallSpacing')};
  padding-bottom: ${theme('dims.basicSpacing')};
  padding-right: ${theme('dims.basicSpacing')};
  padding-left: ${theme('dims.basicSpacing')};

  color: ${theme('colors.error_text')};

  margin-right: ${theme('dims.smallSpacing')};

`;


export const globalErrorModalStyle = {
    content: {
        backgroundColor: '#E32636',
        borderRadius: '10px',
        borderWidth: '0px',
        borderRightWidth: '13px',
        borderLeftWidth: '13px',
        borderLeftColor: '#BE1725',
        borderRightColor: '#BE1725',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px    13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
        padding: '13px',
    }
};
