export const dims = {
    smallSpacing: '5px',
    basicSpacing: '10px',
    bigSpacing: '20px',
    hugeSpacing: '30px',

    basicMargin: '5px',

    forecast: {
        width: '400px'
    }
};