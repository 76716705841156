import { createSelector } from 'reselect';
import { prop } from 'ramda';

import { LOGIN_REDUCER_NAME } from './reducer';

const getLoginReducerState = prop(LOGIN_REDUCER_NAME);

export const loginDetailsSelector = createSelector(
    getLoginReducerState,
    (act_state) => ({ username: act_state.get('username'), password: act_state.get('password') })
);

export const tokenSelector = createSelector(
    getLoginReducerState,
    (act_state) => ({ token: act_state.get('token') })
);

export const authenticatedSelector = createSelector(
    getLoginReducerState,
    (act_state) => ( act_state.get('token') !== '' )
);