import { ignoreElements, map, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { SET_TOKEN } from "./components/Login/const"
import { tokenSelector } from './components/Login/selectors';
import { setToken } from './components/Login/actions';
import {EMPTY, of} from "rxjs";

const TOKEN_KEY = "token";

export const persistEpic = (action$, state$) =>
    action$.pipe(
        ofType(SET_TOKEN),
        map(() => ({
            token: tokenSelector(state$.value)
        })),
        tap(({token}) =>
            {if(token.token !== "") localStorage.setItem(TOKEN_KEY, JSON.stringify(token))}),
        ignoreElements()
    );

export function tokenFromLocalStorageEpic() {
    const maybeToken = localStorage.getItem(TOKEN_KEY);
    if (typeof maybeToken === "string") {
        try {
            const parsed = JSON.parse(maybeToken);
            return of(setToken(parsed.token));
        } catch (e) {
            return EMPTY;
        }
    }
    return EMPTY;
}