export const fonts = {
    basic: {
        fontFamily: "Century Gothic, Futura, sans-serif",
        fontSize: '20px'
    },
    broad: {
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '34px'
    }
};
