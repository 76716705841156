import { NAVIGATE_TO, RESET_NAVIGATION } from './const';
import {fromJS} from "immutable";

export const NAVIGATION_REDUCER_NAME = 'Navigation';

const initialState = fromJS({
    path : ""
});  // Przechowujemy tylko ścieżkę nawigacji jako string


export const navigationReducer = (state = initialState, action) => {
    switch (action.type) {
        case NAVIGATE_TO:
            const { path } = action;
            console.log('trying to redirect to:' + path);
            return state.set("path", fromJS(path))
        case RESET_NAVIGATION:
            return initialState;
        default:
            return state;
    }
};

export default navigationReducer;