import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {BASE_URL} from "../../utils";

const VerifyEmail = () => {
    const { uidb64, token } = useParams();
    const [message, setMessage] = useState("");
    const [error, setError] = useState(null);

    useEffect(() => {
        const verifyAccount = async () => {
            try {
                const response = await fetch(`${BASE_URL}/activate/${uidb64}/${token}/`);
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.error);
                }

                setMessage(data.message);
            } catch (err) {
                setError(err.message);
            }
        };
        verifyAccount()
    }, [uidb64, token]);

    return (
        <div style={{textAlign: "center", marginTop: "20px"}}>
            {error ? (
                <div>{error}</div>
            ) : (
                <div>{message}</div>
            )}
        </div>
    );
};

export default VerifyEmail;
