import { useDispatch } from 'react-redux';
import React, { useState } from "react";
import UserInfo from "../UserInfo";
import Helper from "./Helper";
import { useSelector } from 'react-redux';
import { helpersSelector, getHelpersByUser } from './selectors';
import { HelperProps } from './HelpersWrapper'
import HelpersWrapper1 from "./HelpersWrapper";
import HelpersWrapper12 from "./HelpersWrapper";
import { profileSelector } from "../Profile/selector";
import { ProfileWrapper } from "./ProfileWrapper"
import { AddHelperButton } from "./styles";
import  HelpersByUser  from './Helper'

/*
interface HelperProps {
  helper_name: string; // Helper name
  user: string;
  kierunek: 'kupuj' | 'sprzedaj'; // Direction: "kupuj" (buy) or "sprzedaj" (sell)
  prices: number[]; // Array of 24 prices for each hour of the day
  days_of_week: boolean[]; // Day of the week (0-6, where 0 is Sunday)
  priority: number; // Helper priority
  energy_amount_kwh: number[]; // Energy amount in kilowatt-hours (kWh)
  // Add other necessary or useful properties
}
*/

function Helpers(props: HelperProps) {
  //const dispatch = useDispatch();
  // Data for UserInfo component
  const profileData = useSelector(profileSelector).profile
  //console.log("profdatatest:", profileData.username)
  
  console.log("helper act state:", useState)
  console.log("does selector work:", useSelector(helpersSelector))
  const helpersData: HelperProps[] = useSelector(helpersSelector);
  //console.log("helpersdata123:", useSelector(helpersSelector));
  return (
    <div>
    <div>
      Pomocnicy to niewielkie programy działające zgodnie z Państwa poleceniami, ktore ułatwiają codzienne zajmowanie się platformą i uczestnictwem w aukcjach - tak, aby nie musieli Państwo za każdym razem przygotowywać oferty. Poniżej znajduje się lista pomocników -będą Państwo mieć możliwość wyboru każdego z nich podczas uczestnictwa w aukcjach, a także umożliwienie każdemu z nich automatycznego przystępowania do nich.
    </div>
      {/* Use the UserInfo component with appropriate props */}
      <AddHelperButton>
        Dodaj Pomocnika
      </AddHelperButton>
      <hr />
      {helpersData.map((helper, user) => (
    <HelpersWrapper12 key={user} {...helper} />
))}

      <hr />
      
    </div>
  );
}

export default Helpers;