import { createGlobalStyle } from 'styled-components';
import { theme } from 'styled-tools';

export const GlobalStyle = createGlobalStyle`
    html,
    body,
    #root {
     color: ${theme('colors.text')};
     font-size: ${theme('fonts.basic.fontSize')};
     font-family: ${theme('fonts.basic.fontFamily')};
     height: 100%;
     width: 100%;

     background: linear-gradient(145deg, 
                 ${theme('colors.background')} 5%, 
                 ${theme('colors.second_background')} 70%,
                 ${theme('colors.background')} 95%);
     background-size: cover;
     background-attachment: fixed;
     text-align: center;
    }
`;