import { useDispatch, useSelector } from "react-redux"
import { useCallback, useState } from "react";
import { LoginWrapper } from "./LoginWrapper";
import { Input } from "../Profile/AccountDetails/Input";
import { setLoginDetails } from "./actions";
import { useNavigate } from "react-router-dom";
import { tokenSelector } from "./selectors";
import { useEffect } from "react";

const Login = () => {
    const token = useSelector(tokenSelector);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const onUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const onPasswordChange = (event) => {
        setPassword(event.target.value);
    }


    const onLoginConfirmed = useCallback(
        (event) => {
            event.preventDefault();
            dispatch(setLoginDetails(username, password));
        },
        [dispatch, username, password]
    );

    useEffect(() => {
        if (token.token !== "") {
            navigate('../index.html')
        }
    }, [navigate, token])

    return (
        <LoginWrapper>
            <label > Nazwa użytkownika </label>
            <Input type="text" id="username" name="username" value={username}
                onChange={onUsernameChange} />
            <label > Hasło </label>
            <Input type="password" id="password" name="password" value={password}
                onChange={onPasswordChange} />
            <button onClick={onLoginConfirmed}>Zaloguj</button>
        </LoginWrapper>
    );
}

export default Login;