import { fromJS } from 'immutable';
import { SET_PPE, SET_SELECTED_PPE } from './const';

export const PPE_REDUCER_NAME = 'Ppe';


const initialState = fromJS({
    'ppe': [],
    'selected_ppe': 1
});


export const ppeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PPE: {
            const ppe = action.payload;
            const ppe_n = fromJS(ppe)
            console.log('setting ppe to:' + ppe);
            if(ppe_n.first() !== undefined) {
                return state.set("ppe", fromJS(ppe)).set("selected_ppe", ppe_n.first().get("id"))
            } else {
                return state;
            }
        }
        case SET_SELECTED_PPE: {
            const id = action.payload;
            console.log('setting selected ppe to:' + id);
            return state.set("selected_ppe", id)
        }
        default:
            return state;
    }
};