import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { setAddedPPE } from "../actions";

function AddPPE() {
    const [capacity, setCapacity] = useState('3000');
    const [meterNumber, setMeterNumber] = useState('');

    const dispatch = useDispatch();


    const onAddPPEClick = useCallback((event: { preventDefault: () => void; }) => {
        event.preventDefault();
        dispatch(setAddedPPE({
            "capacity": capacity,
            "meter_number": meterNumber
        }));
    }, [capacity, meterNumber]);

    return (
        <div>
                <label>Numer licznika</label>
                <input type="string" id="meter_number" name="meter_number" value={meterNumber} onChange={event => setMeterNumber(event.target.value)} />
                <label>Maksymalna pojemność</label>
                <input type="number" id="capacity" pattern="[0-9]*" name="capacity" value={capacity} onChange={event => setCapacity(event.target.value)} />
            <button onClick={onAddPPEClick}>Dodaj PPE</button>`
        </div>
    );
}

export default AddPPE;
