import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearToken } from "../Login/actions";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "./api";
import { useSelector } from 'react-redux';
import { tokenSelector } from '../Login/selectors';

function Logout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector(tokenSelector)

    useEffect(() => {
        console.log('logout');
        logoutUser(token)
            .then(localStorage.clear())
            .then(dispatch(clearToken()))
            .then(navigate("/"));
    }, [dispatch, navigate, token]);

    return (
        <p>Logout</p>
    )
}
export default Logout;