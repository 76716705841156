import { BASE_URL } from "../../utils";

export const getTokenFromLogin = async (loginDetails) => {
    try {
        const response = await fetch(`${BASE_URL}/auth/login/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginDetails)
        });

        //This solution informs user if he hasn't verified his account.
        if (!response.ok) {
            const errorData = await response.json().catch(() => null);
            const errorMessage = errorData ? (errorData.message) : response.statusText;
            return { success: false, errorMessage: errorMessage };
        }
        
        const result = await response.json();
        return { success: true, token: result.token };
    } catch (error) {
        return { success: false, errorMessage: error.message };
    }
};