import { DocWrapper } from "./styles";
import styled from "styled-components";
import { theme } from 'styled-tools';
import DownloadLink from "./DownloadLink";
import MonthlySummariesList from "./MonthlySummariesList";

const DocBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme('dims.basicSpacing')};
  margin: ${theme('dims.bigSpacing')};
  border-radius: 5px;
  color: ${theme('colors.panel_text')};
  background: linear-gradient(180deg, ${theme('colors.panel_background')}, ${theme('colors.second_panel_background')});
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

function MyDoc() {
    return (
        <DocWrapper>
            <DocBoxWrapper style={{ width: '60%'}}>
                <div style={{textAlign: 'center'}}>Moje dokumenty</div>
                <hr />
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    {/* Left buttons */}
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'flex-start' }}>
                        <div>
                            <div>Regulamin Korzystania z Platformy</div>
                            <DownloadLink />
                        </div>
                        <div>
                            <div>Polityka prywatnosci</div>
                            <DownloadLink />
                        </div>
                        <div>
                            <div>Informacje o platformie</div>
                            <DownloadLink />
                        </div>
                        <div>
                            <div>Umowa sprzedazy energii</div>
                            <DownloadLink />
                        </div>
                        <div>
                            <div>Umowa o swiadczenie uslug podmiotu odpowiedzialnego za bilansowanie</div>
                            <DownloadLink />
                        </div>
                        <div>
                            <div>Umowa o swiadczenie uslugi agregacji</div>
                            <DownloadLink />
                        </div>
                        <div>
                            <div>Koncesja na sprzedaz energii</div>
                            <DownloadLink />
                        </div>
                        <div>
                            <div>Koncesja na wytwarzanie energii</div>
                            <DownloadLink />
                        </div>
                        <div>
                            <div>Miesięczne podsumowania</div>
                            <MonthlySummariesList />
                        </div>
                    </div>
                </div>
            </DocBoxWrapper>
        </DocWrapper>
    );
}

export default MyDoc;
