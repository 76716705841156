import styled from 'styled-components';
import { theme } from 'styled-tools';

export const PPEInfoWrapper = styled.div`
    width: 40%;
    margin: ${theme('dims.bigSpacing')};
`;

export const PPEWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    padding: ${theme('dims.basicSpacing')};
    margin: ${theme('dims.bigSpacing')};
}
`;