import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import { RegistrationWrapper, HorizontalContainer, ColumnContainer } from "./RegistrationWrapper";
import { Input } from "../Profile/AccountDetails/Input";
import {resetNavigation, setRegisterDetails } from "./actions";
import { useNavigate } from "react-router-dom";
import {navigationSelector} from "./selectors";
import {setGlobalError} from "../Error/actions"; // Importujemy akcję

const Registration = () => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const onEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const onUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const onPasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const onConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    }

    const pattern = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")

    function isANumber(str) {
        return !/\D/.test(str);
    }

    const navigatePath = useSelector(navigationSelector);

    useEffect(() => {
        if (navigatePath) {
            navigate(navigatePath);  // Przekierowanie na stronę
            dispatch(resetNavigation());
        }
    }, [navigatePath, navigate, dispatch]);

    const onRegisterConfirmed = useCallback(
        (event) => {
            event.preventDefault();
            const registerDetails = {
                email: email,
                username: username,
                password: password,
                confirmPassword: confirmPassword
            };
            if(password !== confirmPassword) {
                dispatch(setGlobalError("Given passwords are not identical."))
            }else if(!pattern.test(password)){
                dispatch(setGlobalError("Password has to contain 8 characters, at least one letter, one number and one special character."))
            }else{
                dispatch(setRegisterDetails(registerDetails));
            }
        },
        [dispatch, email, username, password, confirmPassword]
    );

    return (
        <RegistrationWrapper>
            <div><b>Strona w budowie - tymczasowo prosimy nie podawać prawdziwych danych osobowych.</b></div>
            <HorizontalContainer>
                <ColumnContainer>
                    <label>Email<span style={{color: 'red'}}>*</span></label>
                    <Input type="text" id="email" name="email" value={email} onChange={onEmailChange}/>
                    <label> Nazwa użytkownika<span style={{color: 'red'}}>*</span></label>
                    <Input type="text" id="username" name="username" value={username} onChange={onUsernameChange}/>
                </ColumnContainer>
                <ColumnContainer>
                    <label>Hasło<span style={{color: 'red'}}>*</span></label>
                    <Input type="password" id="password" name="password" value={password} onChange={onPasswordChange} />
                    <label>Potwierdź hasło<span style={{ color: 'red' }}>*</span></label>
                    <Input type="password" id="password2" name="password2" value={confirmPassword} onChange={onConfirmPasswordChange} />
                </ColumnContainer>
            </HorizontalContainer>
            <div><span style={{ color: 'red' }}>*</span> - pole obowiązkowe</div>
            <button onClick={onRegisterConfirmed}>Zarejestruj</button>
        </RegistrationWrapper>
    );
}

export default Registration;
