import { createSelector } from 'reselect';
import { prop } from 'ramda';

import { ERROR_REDUCER_NAME } from './reducer';

const getErrorReducerState = prop(ERROR_REDUCER_NAME);

export const errorSelector = createSelector(
    getErrorReducerState,
    (act_state) => (act_state.get('message'))
);
