import { BASE_URL, EMPTY_PROFILE, EMPTY_TRANSFERS  } from "../../utils";

export const getProfileFromServer = async (token) => {
    try {
        const response = await fetch(`${BASE_URL}/user/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token.token}`
            }
        });
      
        if (!response.ok) {
            console.log(response)
            return {
                'profile': EMPTY_PROFILE,
                'transfers': EMPTY_TRANSFERS
            }
        }

        const profileJson = await response.json()

        const transfers = await fetch(`${BASE_URL}/user/transfers/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token.token}`
            }
        });

        if (!transfers.ok) {
            return {
                'profile': profileJson,
                'transfers': EMPTY_TRANSFERS
            }
        }
        
        const transfersJson = await transfers.json()
    
        return {
            'profile': profileJson,
            'transfers': transfersJson
        }
    } catch (error) {
        console.log(error)
        // TODO
    }
}

export const editProfile = async(profile, token) => {
    try {
        const response = await fetch(`${BASE_URL}/user/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token.token}`
            },
            body: JSON.stringify(profile)
        });

        if (!response.ok) {
            let errorData = await response.json().catch(() => null);
            let errorMessage = 'Profile edit failed';
            if (errorData) {
            errorMessage = Object.entries(errorData)
                .map(([field, errors]) => {
                    const formattedField = field.replace(/_/g, ' ');
                    return `${formattedField}: ${errors.join(', ')}`;
                })
                .join('; ');
            }
            return { success: false, errorMessage, EMPTY_PROFILE };
        }
        return response.json();
    } catch (error) {
        return { success: false, errorMessage: error.message, EMPTY_PROFILE };
    }
}