import { SET_PROFILE_DATA, SET_EDITED_PROFILE, SET_PROFILE_NEEDS_UPDATE  } from "./const";

export const setProfileData = (profile) => ({
    type: SET_PROFILE_DATA,
    payload: profile
})

export const setEditedProfile = (profile) => ({
    type: SET_EDITED_PROFILE,
    payload: profile
})

export const setProfileNeedsUpdate = () => ({
    type: SET_PROFILE_NEEDS_UPDATE
})