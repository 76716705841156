import { createSelector } from 'reselect';
import { prop } from 'ramda';

import { AUCTIONS_REDUCER_NAME } from './reducer';

const getAuctionsReducerState = prop(AUCTIONS_REDUCER_NAME);

export const auctionsSelector = createSelector(
    getAuctionsReducerState,
    (act_state) => (act_state.get('auctions'))
);

export const placedOfferSelector = createSelector(
    getAuctionsReducerState,
    (act_state) => (act_state.get('placedOffer'))
);

export const getOffersByKey = createSelector(
    getAuctionsReducerState, (state, auctionId) => auctionId,
    (act_state, auctionId) => {
        if (act_state.get('offers').get(auctionId) !== undefined){
            return act_state.get('offers').get(auctionId);
        } else {
            return [];
        }
    }
  );