import styled from 'styled-components';
import { theme } from 'styled-tools';
import { TransactionType } from './TransactionType';

type TransactionProps = {
  type?: TransactionType;
};

// Use the defined type for your styled component
export const Amount = styled.div<TransactionProps>`
  margin-left: ${theme('dims.bigSpacing')};
  font-weight: bold;
  text-align: right;
  flex: 1;
  ${({ type }) => type === TransactionType.Withdrawal && `
    color: #970909;
  `}
  ${({ type }) => type === TransactionType.Deposit && `
    color: #156437;
  `}
`;