import styled from 'styled-components';
import { theme } from 'styled-tools';

export const DocInfoWrapper = styled.div`
    width: 40%;
    margin: ${theme('dims.bigSpacing')};
`;

export const DocWrapper = styled.div`
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: center;
    padding: ${theme('dims.basicSpacing')};
    margin: ${theme('dims.bigSpacing')};
`;

export const LinkButton = styled.a`
  display: inline-block;
  padding: 12px 20px;
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-decoration: None;

  &:hover {
    background-color: #0056b3;
  }
`;

export const CollapseButton = styled.button`
  display: inline-block;
  padding: 12px 20px;
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

export const FileToDownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  {/* Styles for children div */} 
  & > div {
    font-size: 14px;
  }

  & > button {
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #218838;
    }
  }
    
`;

