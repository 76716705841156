import { SET_ADDED_PPE, SET_SELECTED_PPE, SET_PPE_NEED_UPDATE, SET_PPE } from "./const"

export const setAddedPPE = (ppe) => ({
    type: SET_ADDED_PPE,
    ppe
})

export const setPPENeedUpdate = () => ({
    type: SET_PPE_NEED_UPDATE
})

export const setPPE = (ppe) => ({
    type: SET_PPE,
    payload: ppe
})

export const setSelectedPPE = (id) => ({
    type: SET_SELECTED_PPE,
    payload: id
})