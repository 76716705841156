import { fromJS } from 'immutable';
import { SET_REGISTER_DETAILS } from './const';

export const REGISTER_REDUCER_NAME = 'Register';

const initialState = fromJS({   
    registerDetails: {
        email: "",
        username: "",
        password: ""
    }
})

export const registerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REGISTER_DETAILS: {
            const { registerDetails } = action;
            console.log('trying to register with name:' + registerDetails.username + " and password: " + registerDetails.password);
            return state.set("registerDetails", fromJS(registerDetails))
        }
        default:
            return state;
    }
}