import { parseDate } from "../../../../utils";
import { AuctionDataWrapper } from "./AuctionDataWrapper";
import { AuctionDetailsWrapper } from "./AuctionDetailsWrapper";
import { AuctionOptionsWrapper } from "./AuctionOptionsWrapper";
import { AuctionStatus } from "./AuctionStatus";
import { DateTextWrapper } from './DateWrapper'
import PlaceOffer from "./PlaceOffer";
import { StatusWrapper } from "./StatusWrapper";

interface AuctionDataProps {
    startTime: string,
    endTime: string,
    tradeTime: string,
    status: AuctionStatus,
    id: string
}

function AuctionData(props: AuctionDataProps) {
    return (
        <AuctionDataWrapper>
            <AuctionDetailsWrapper>
                <StatusWrapper $active={props.status}>
                    {props.status}
                </StatusWrapper>
                <div>
                    <b>Początek</b>
                    <DateTextWrapper>
                        {parseDate(props.startTime)}
                    </DateTextWrapper>
                </div>
                <div>
                    <b>Koniec</b>
                    <DateTextWrapper>
                        {parseDate(props.endTime)}
                    </DateTextWrapper>
                </div>
                <div>
                    <b>Dostawa</b>
                    <DateTextWrapper>
                        {parseDate(props.tradeTime)}
                    </DateTextWrapper>
                </div>
            </AuctionDetailsWrapper>
            <AuctionOptionsWrapper>

                {props.status === AuctionStatus.Open && <div className="auction-element">
                    <PlaceOffer id={props.id} ></PlaceOffer>
                </div>}
            </AuctionOptionsWrapper>

        </AuctionDataWrapper>

    )
}

export default AuctionData;