import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { deleteOffer } from '../../../actions';
import { OfferWrapper } from './OfferWrapper';
import { DataOfferWrapper } from './DataOfferWrapper';
import { DeleteButton } from './DeleteButton';

const Offer = ({ id, auction, amount, price, direction }) => {
    const dispatch = useDispatch();

    const deleteOfferClick = useCallback((id) => {
        const isConfirmed = window.confirm('Czy jesteś pewien, że chcesz usunąć ofertę?');
        console.log(id, auction);
        if (isConfirmed) {
            dispatch(deleteOffer(id, auction));
        }
    }, [dispatch, auction]);

    return (
        <OfferWrapper>
            <DataOfferWrapper>
                <div><b>Numer: </b> {id}</div>
                <div><b>Ilość energii: </b> {amount}</div>
                <div><b>Cena: </b> {price}</div>
                <div><b>Rodzaj: </b> {direction}</div>
            </DataOfferWrapper>
            <DeleteButton onClick={() => deleteOfferClick(id)}>Usuń Ofertę</DeleteButton>
            <hr></hr>
        </OfferWrapper>
    );
};

export default Offer;