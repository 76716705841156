import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authenticatedSelector } from './Login/selectors';

const PrivateRoute = () => {
    const isAuthenticated = useSelector(authenticatedSelector);
    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};  

export default PrivateRoute;
