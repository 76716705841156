import { Value, PPEFieldWrapper, PPEWrapper, StatusWrapper } from "./styles";

export interface PPEProps {
    id: number;
    address: string;
    capacity: number;
    tariff_group: string;
    operator_name: string;
    meter_number: string;
    energymeter: string;
    balance_unit: string;
    blocked_capacity: number;
    status: string;
    total_sold: number;
    total_bought: number;
}

function DetailedPPE(props: PPEProps) {
    return (
        <PPEWrapper>
            {props.id !== null && props.id !== undefined && (
                <PPEFieldWrapper style={{'marginBottom': '0.5em'}}>
                    Numer PPE: <Value>{props.id}</Value>
                </PPEFieldWrapper>
            )}
            {props.address && (
                <PPEFieldWrapper>
                    Adres: <Value>{props.address}</Value>
                </PPEFieldWrapper>
            )}
            {props.capacity !== null && props.capacity !== undefined && (
                <PPEFieldWrapper>
                    Moc maksymalna[kW]: <Value>{props.capacity}</Value>
                </PPEFieldWrapper>
            )}
            {props.tariff_group && (
                <PPEFieldWrapper>
                    Grupa taryfowa: <Value>{props.tariff_group}</Value>
                </PPEFieldWrapper>
            )}
            {props.operator_name && (
                <PPEFieldWrapper>
                    Operator: <Value>{props.operator_name}</Value>
                </PPEFieldWrapper>
            )}
            {props.meter_number && (
                <PPEFieldWrapper>
                    Numer licznika: <Value>{props.meter_number}</Value>
                </PPEFieldWrapper>
            )}
            {props.balance_unit && (
                <PPEFieldWrapper>
                    Jednostka bilansowa: <Value>{props.balance_unit}</Value>
                </PPEFieldWrapper>
            )}
            {props.blocked_capacity !== null && props.blocked_capacity !== undefined && (
                <PPEFieldWrapper>
                    Zablokowana pojemność: <Value>{props.blocked_capacity}</Value>
                </PPEFieldWrapper>
            )}
            <PPEFieldWrapper>
                Całkowita sprzedaż: <Value>{props.total_sold}</Value>
            </PPEFieldWrapper>
            <PPEFieldWrapper>
                Całkowite kupno: <Value>{props.total_bought}</Value>
            </PPEFieldWrapper>
            {props.status && (
                <PPEFieldWrapper>
                    Status PPE: <StatusWrapper $status={props.status}>{props.status}</StatusWrapper>
                </PPEFieldWrapper>
            )}
        </PPEWrapper>
    )
}

export default DetailedPPE;
