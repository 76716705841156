import { combineEpics } from 'redux-observable';
import { updateAuctionsEpic } from './components/AuctionMenu/epics';
import { updateHelpersEpic } from './components/Helpers/epics';
import { getTokenFromRegisterEpic } from './components/Registration/epics';
//import { updateOffersEpic } from './components/History/OffersList/epics';
import { fetchProfileEpic } from './components/Profile/epics';
import { getTokenFromLoginEpic } from './components/Login/epics';
import { fetchOffersForAuctionEpic } from './components/AuctionMenu/epics';
import { postOfferEpic, deleteOfferEpic } from './components/AuctionMenu/epics';
import { editProfileEpic } from './components/Profile/epics';
import { persistEpic } from './persist';
import { tokenFromLocalStorageEpic } from './persist';
import { fetchPPEEpic, postPPEEpic } from './components/MyPPE/epics';

export const rootEpic = combineEpics( updateHelpersEpic, getTokenFromLoginEpic, updateAuctionsEpic, getTokenFromRegisterEpic, /*updateOffersEpic,*/ fetchProfileEpic, fetchOffersForAuctionEpic, postOfferEpic, editProfileEpic, deleteOfferEpic, fetchPPEEpic, postPPEEpic, persistEpic, tokenFromLocalStorageEpic);
