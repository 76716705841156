import styled from 'styled-components';
import { theme } from 'styled-tools';

export const ProfileWrapper = styled.div`
    display: flex;
    flex-direction: row;
    text-align: left;
    padding: ${theme('dims.basicSpacing')};
    margin: ${theme('dims.bigSpacing')};
}
`;