import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { SET_REGISTER_DETAILS } from './const';
import { registerDetailsSelector } from './selectors';
import { getTokenFromRegister } from './api';
import { setGlobalError } from '../Error/actions';
import {navigateTo} from "./actions";

export const getTokenFromRegisterEpic = (action$, state$) =>
    action$.pipe(
        ofType(SET_REGISTER_DETAILS),
        map(() => ({
            registerDetails: registerDetailsSelector(state$.value)
        })),
        switchMap(({ registerDetails }) =>
            from(getTokenFromRegister(registerDetails)).pipe(
                switchMap(({ success, errorMessage }) =>
                    success ?
                    of(navigateTo('/registration-success')) :
                    of(setGlobalError(errorMessage))
                )
            )
        )
    );
