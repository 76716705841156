import { createSelector } from 'reselect';
import { prop } from 'ramda';
import { PROFILE_REDUCER_NAME } from './reducer';

const getProfileReducerState = prop(PROFILE_REDUCER_NAME);

export const profileSelector = createSelector(
    getProfileReducerState,
    (profileState) => profileState ? profileState.toJS() : {}
);

export const editedProfileSelector = createSelector(
    getProfileReducerState,
    (profileState) => (profileState.get('editedProfile'))
);