import UserInfo from "../UserInfo";
import Auction from "./Auction";
import { useSelector } from 'react-redux';
import { auctionsSelector } from "../AuctionMenu/selectors";
import { profileSelector } from "../Profile/selector";
import { generateInvoicesForLastMonth } from './generateInvoice.js';
import { HistoryWrapper } from "./HistoryWrapper";

function History() {
  const profileData = useSelector(profileSelector).profile;
  let auctionsData = useSelector(auctionsSelector);

  // get only auctions with transactions user took part in
  auctionsData = auctionsData.filter(auction => {
      return auction.transactions.length > 0
  })

  // Sort starting from newest
  auctionsData.sort((x, y) => {
    return new Date(y.end_time) - new Date(x.end_time);
  });


  const handleGenerateLastMonthInvoices = () => {
    const now = new Date();
    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    
    const filteredTransactions = auctionsData.flatMap(auction => 
      auction.transactions.filter(transaction => {
        const transactionDate = new Date(auction.trade_time);
        return transactionDate >= lastMonth && transactionDate <= now;
      })
    );

    generateInvoicesForLastMonth(filteredTransactions);
  };

  return (  
    <HistoryWrapper>
      <UserInfo {...profileData} />
      <button onClick={handleGenerateLastMonthInvoices}>Pobierz faktury za ostatni miesiąc</button>
      <div className="history-header">
        Historia ofert
      </div>
      <hr />
      {auctionsData.map((data, index) => (
        <Auction key={index} {...data} />     
      ))}
      <hr />
    </HistoryWrapper>
  );
}

export default History;
