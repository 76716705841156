import {BASE_URL} from "../../utils";
import {useDispatch, useSelector} from "react-redux";
import {tokenSelector} from "../Login/selectors";
import Collapsible from "./Collapsible";
import {useEffect, useState} from "react";
import {setGlobalError} from "../Error/actions";
import {FileToDownloadWrapper} from "./styles";

// Fetches list of filenames for all monthly summaries for this user
async function getFilesList(token: string) {
    const response = await fetch(`${BASE_URL}/get_files_list/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${token}`
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch monthly summaries");
    }

    return response.json()
}

// Fetches monthly summary called `filename`
async function getFile(filename: string, token: string) {
    const response = await fetch(`${BASE_URL}/get_file/?filename=${filename}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${token}`
        }
    });

    if (!response.ok) {
        throw new Error('Failed to fetch file ' + filename);
    }

    const blob = await response.blob()

    // Download file
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return response;
}

function format_filename(filename: string) {
    const match = filename.match(/data-(\d{2})-(\d{4})_ppe-(\d+)\.xlsx/);

    if (match) {
        const month = match[1];
        const year = match[2];
        const ppeNumber = match[3];
        return [month, year, ppeNumber]
    }
    return []
}

export default function MonthlySummariesList() {
    const dispatch = useDispatch();
    const token = useSelector(tokenSelector).token;
    const [files, setFiles] = useState(Array<string>)

    // Get files list
    useEffect(() => {

        const fetchFiles = async () => {
            try {
                const response = await getFilesList(token)
                setFiles(response.user_files)
            } catch (error: any) {
                dispatch(setGlobalError(error.message));
            }
        }
        fetchFiles()

    }, [dispatch, token])

    return (
        <Collapsible>
            {/* Display list of summaries */}
            {files.map((filename) => {
                return <FileToDownloadWrapper key={filename}>

                    <div>Data: {format_filename(filename)[0]}-{format_filename(filename)[1]}</div>
                    <div>PPE: {format_filename(filename)[2]}</div>
                    <button onClick={async () => {
                        try {
                            await getFile(filename, String(token))
                        } catch (error:any) {
                            dispatch(setGlobalError(error.message));
                        }
                    }}>Pobierz</button>

                </FileToDownloadWrapper>
            })}

            {files.length === 0 &&
                <div>
                    Brak plików
                </div>
            }

        </Collapsible>
    )
}

