import { fromJS } from 'immutable';
import { SET_PROFILE_DATA, SET_EDITED_PROFILE } from './const';
import { EMPTY_PROFILE, EMPTY_TRANSFERS } from '../../utils';

export const PROFILE_REDUCER_NAME = 'Profile';

const initialState = fromJS({
    'profile': EMPTY_PROFILE,
    'transfers': EMPTY_TRANSFERS,
    'ppe': [],
    'selected_ppe': 1
});

export const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILE_DATA: {
            console.log('setting profile data to:' + action.payload)
            return state.merge(fromJS(action.payload));
        }
        case SET_EDITED_PROFILE: {
            const profile = action.payload;
            console.log('editing profile to:' + profile);
            return state.set("editedProfile", fromJS(profile))
        }
        default:
            return state;
    }
};