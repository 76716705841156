import styled from 'styled-components';
import { theme } from 'styled-tools';
import { AuctionStatus } from './AuctionStatus';

type StatusWrapperProps = {
  $active: AuctionStatus;
};

export const StatusWrapper = styled.div<StatusWrapperProps>`
  display: flex;
  flex-direction: row;
  max-height: 25px;
  padding: ${theme('dims.smallSpacing')};
  margin-right: ${theme('dims.bigSpacing')};
  border-radius: 25px;
  color: ${theme('colors.panel_text')};
  background: ${({ $active }) => (
    $active === AuctionStatus.Closed ? '#D8415F' :
    ($active === AuctionStatus.Open ? '#33955D' : 'transparent')
  )};
`;