import {NAVIGATE_TO, RESET_NAVIGATION, SET_REGISTER_DETAILS} from "./const"

export const setRegisterDetails = (registerDetails) => ({
    type: SET_REGISTER_DETAILS,
    registerDetails
})

export const navigateTo = (path) => ({
    type: NAVIGATE_TO,
    path
});

export const resetNavigation = () => ({
  type: RESET_NAVIGATION,
});