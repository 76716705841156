
import { EnergymeterDetailsWrapper, EnergymeterFieldDataWrapper, EnergymeterFieldLabelWrapper, EnergymeterFieldWrapper } from "./EnergymeterDetailsWrapper";

export interface EnergymeterDetailsProps {
    state: string;
    capacity: string;
    blocked_capacity: string;
}

function EnergymeterDetails(props: EnergymeterDetailsProps) {
    return (
        <EnergymeterDetailsWrapper>
            <EnergymeterFieldWrapper>
                <EnergymeterFieldLabelWrapper>Stan licznika</EnergymeterFieldLabelWrapper>
                <EnergymeterFieldDataWrapper className="energymeter-details-field">
                    {props.state} kWh
                </EnergymeterFieldDataWrapper>
            </EnergymeterFieldWrapper>
            <EnergymeterFieldWrapper>
                <EnergymeterFieldLabelWrapper>Dostępna moc</EnergymeterFieldLabelWrapper>
                <EnergymeterFieldDataWrapper className="energymeter-details-field">
                    {props.capacity} kW
                </EnergymeterFieldDataWrapper>
            </EnergymeterFieldWrapper>
            <EnergymeterFieldWrapper>
                <EnergymeterFieldLabelWrapper>Zablokowana moc</EnergymeterFieldLabelWrapper>
                <EnergymeterFieldDataWrapper className="energymeter-details-field">
                    {props.blocked_capacity} kW
                </EnergymeterFieldDataWrapper>
            </EnergymeterFieldWrapper>
        </EnergymeterDetailsWrapper>
    )
}

export default EnergymeterDetails;