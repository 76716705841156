import { ChartWrapper } from './ChartWrapper';
import { CartesianGrid, LineChart, XAxis, YAxis, Tooltip, Line, Legend } from 'recharts';
import { auctionsSelector } from '../selectors';
import { useSelector } from 'react-redux';
import { AuctionProps } from '../../History/Auction';
import { parseDateShort } from '../../../utils';

function Chart() {
    const auctions: AuctionProps[] = useSelector(auctionsSelector)
    auctions.sort((a, b) => {
        const dateA: Date = new Date(a.end_time);
        const dateB: Date = new Date(b.end_time);
        return dateA.getTime() - dateB.getTime();
    }).slice(-10);

    const prices = auctions.map(obj => ({ price: obj.price, time: parseDateShort(obj.end_time) }))
    const maxPrice = prices.reduce((max, obj) => Math.max(max, obj.price), -Infinity);
    const minPrice = prices.reduce((max, obj) => Math.min(max, obj.price), +Infinity);

    return (
        <ChartWrapper>
            <LineChart width={600} height={300} data={prices} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="6 6" />
                <XAxis dataKey={"time"} padding={{ right: 10, left: 10 }} style={{ fontSize: '0.6rem' }} tick={{ stroke: 'black', strokeWidth: 0.5 }} />
                <YAxis dataKey={"price"} domain={[minPrice, maxPrice]} type="number" style={{ fontSize: '0.6rem' }} tick={{ stroke: 'black', strokeWidth: 0.5 }} />
                <Tooltip />
                <Legend />
                <Line name="Cena" className='line' type="monotone" dataKey="price" isAnimationActive={false} strokeWidth={3} />
            </LineChart>
        </ChartWrapper>
    );
}

export default Chart;